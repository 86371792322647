/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/react-in-jsx-scope */

import {ContentHeader} from '@app/components';
import RichTextEditor from '@app/components/RichTextEditor';
import {useFormik} from 'formik';
import {SetStateAction, useEffect, useMemo, useRef, useState} from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {PfButton} from '@profabric/react-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {log} from 'console';
import i18n from '@app/utils/i18n';

import * as Yup from 'yup';
import * as languageServices from '../../services/controller/language/fetch_laguage';
import * as bisnisServices from '../../services/controller/lini-bisnis/fetch_lini_bisnis';
import * as bisnisServicesPost from '../../services/controller/lini-bisnis/post_content_data';

const CreateNewBisnis = () => {
  const [language, setLanguage] = useState([]);
  const [statusType, setStatusType] = useState([]);
  const [contentData, setContentData] = useState('');
  const [optionsLang, setOptionsLang] = useState('');
  const [optionsStatus, setOptionsStatus] = useState('');

  const [filesThumb, setFilesThumb] = useState<any | null>(null);
  const [filesImageHeader, setFilesImageHeader] = useState<any | null>(null);
  const [imagePreviewHead, setImagePreviewHead] = useState<any | null>(null);
  const [imagePreviewThumb, setImagePreviewThumb] = useState<any | null>(null);
  const [multipleFile, setMultipleFile] = useState<any | null>(null);

  const [isAuthLoading, setAuthLoading] = useState(false);
  const [currentType, setCurrentType] = useState('');
  const [currentLang, setCurrentLang] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const [titles, setTitles] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [locationData, setLocationData] = useState('');
  const [imageBody, setImageBody] = useState('');

  const cid =
    typeof location.state !== 'undefined' && location.state != null
      ? location.state.cid.contentId
      : null;
  const dataType =
    typeof location.state !== 'undefined' && location.state != null
      ? location.state.type
      : null;

  console.log('CreateNewBisnis', cid);
  const slugify = (str: any) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

  const getContent = (value: any) => {
    setContentData(value);
  };

  const handleFileThumbnail = (event: any) => {
    setImagePreviewThumb('');
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const url = URL.createObjectURL(file);
      setImagePreviewThumb(url);
      setFilesThumb(file);
      // console.log('log-data-image', file);
    }
  };

  const handleFileGallery = (event: any) => {
    const files = event.target.files;
    const imageGallery = [];
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      console.log('element', element);
      imageGallery.push(element);
    }
    setMultipleFile(imageGallery);
    // console.log(multipleFile);
  };

  const handleSelectedContentType = (e: any) => {
    console.log('selected', e.target.value);
    const selectedValue =
      typeof e !== 'undefined' && e != '' && e != null ? e.target.value : '';
    setCurrentType(selectedValue);
    let index = 0;
    let statusTypes = '';
    for (index = 0; index < statusType.length; index++) {
      const element = statusType[index];
      const value = element;
      console.log(value);
      if (value['type_name'] == selectedValue) {
        statusTypes = value['lni_type_id'];
      }
    }
    console.log('selected type', currentType);
    setOptionsStatus(statusTypes);
  };

  const handleSelectedLang = (e: any) => {
    console.log('selected', e.target.value);
    const selectedValue =
      typeof e !== 'undefined' && e != '' && e != null ? e.target.value : '';
    setCurrentLang(selectedValue);
    let index = 0;
    let lang = '';
    for (index = 0; index < language.length; index++) {
      const element = language[index];
      const value = element;
      console.log(value['lang_name']);
      if (value['lang_name'] == selectedValue) {
        lang = value['lang_id'];
      }
    }
    console.log('selected language', lang);
    setOptionsLang(lang);
  };

  const handleSubmit = (event: any) => {
    console.log('multiple', multipleFile);
    event.preventDefault();
    // let param: any = {};
    const formData = new FormData();
    if (titles.length == 0) {
      alert(`Must be 10 characters or more`);
    }

    if (multipleFile != null) {
      for (let i = 0; i < multipleFile.length; i++) {
        formData.append(`img_${i}`, multipleFile[i]);
      }
    }

    if (dataType == 'edit') {
      formData.append('lini_id', cid);
      formData.append('lini_title', titles);
      formData.append('lini_description', contentData);
      formData.append('lini_slug', slugify(titles));
      formData.append('lini_type_id', optionsStatus);
      formData.append('lang_id', optionsLang);
      formData.append('image_thumbnail', filesThumb);
      // param = {
      //   lini_title: titles,
      //   lini_description: contentData,
      //   lini_slug: slugify(titles),
      //   lini_type_id: optionsStatus,
      //   lang_id: optionsLang,
      //   image_thumbnail: filesThumb,
      //   lini_id: cid
      // };
    } else {
      formData.append('lini_title', titles);
      formData.append('lini_description', contentData);
      formData.append('lini_slug', slugify(titles));
      formData.append('lini_type_id', optionsStatus);
      formData.append('lang_id', optionsLang);
      formData.append('image_thumbnail', filesThumb);
      // param = {
      //   lini_title: titles,
      //   lini_description: contentData,
      //   lini_slug: slugify(titles),
      //   lini_type_id: optionsStatus,
      //   lang_id: optionsLang,
      //   image_thumbnail: filesThumb,

      // };
    }
    console.log(formData);
    postContent(formData);
  };

  const postContent = async (formData: any) => {
    try {
      let result = null;
      if (dataType == 'edit') {
        console.log(`log-message-here-edit ${dataType}`, formData);
        result = await bisnisServicesPost.editContentBisnis(formData);
      } else {
        // console.log(`log-message-here ${dataType}`, formData);
        result = await bisnisServicesPost.sendContentBisnis(formData);
        // console.log('log-result-post', result);
      }
      if (result['status_id'] == 1) {
        navigate('/lini-bisnis/property');
      }
    } catch (error: any) {
      console.log(error.message);
      // toast.error(error.message);
      setAuthLoading(false);
    }
  };

  const fetchStatusType = async () => {
    try {
      const response = await bisnisServices.getBisnisType();
      console.log('log-response-type', response);
      if (response != null) {
        setStatusType(response);
        if (dataType == 'edit') {
          let status = '';
          // let id = '';
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            console.log('element-status', element);
            if (element.lini_type_id == optionsStatus) {
              status = element.type_name;
              // id = element.lini_type_id;
            }
          }
          setCurrentType(status);
          // setOptionsStatus(id);
        } else {
          setCurrentType(response[0].type_name);
        }
      }
    } catch (error) {
      setStatusType([]);
    }
  };

  const fetchContentLang = async () => {
    try {
      const response = await languageServices.getLanguage();
      console.log('log-response-inner-language', response[2].lang_name);
      if (response != null) {
        setLanguage(response);
        if (dataType == 'edit') {
          let lang = '';
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            console.log('element-language', element);
            if (element.lang_id == optionsLang) {
              lang = element.lang_name;
            }
            setCurrentLang(lang);
          }
        } else {
          setCurrentLang(response[2].lang_name);
        }
      }
    } catch (error) {
      setLanguage([]);
    }
  };

  const fetchDetail = async () => {
    try {
      const paramCid = cid;
      const response = await bisnisServices.getDetailBisnis(paramCid);
      // console.log('log-response-inner-type', response['content']);
      if (response != null) {
        const title =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.lini_title
            : '';
        const location =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.location
            : '';
        const contactNumber =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.contact
            : '';
        const image =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.image
            : '';
        const imageBody =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? image.thumbnail_path
            : '';
        const imageHeader =
          typeof image !== 'undefined' && image !== null && image !== 'null'
            ? image.header_path
            : '';
        const content =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.lini_description
            : '';
        const language =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.lang_id
            : '';
        const status =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.lini_type_id
            : '';

        setContentData(content);
        setTitles(title);
        setLocationData(location);
        setContactNumber(contactNumber);
        setContentData(content);
        setImageBody(imageBody);
        setFilesThumb(imageBody);
        setImagePreviewThumb(imageBody);
        setOptionsLang(language);
        setOptionsStatus(status);
      }
    } catch (error) {
      setContentData('');
    }
  };

  useEffect(() => {
    if (dataType == 'edit') {
      fetchDetail();
    }
    fetchStatusType();
    fetchContentLang();
  }, []);

  return (
    <div>
      <ContentHeader
        title={
          dataType == 'edit' && dataType != null
            ? i18n.t('menusidebar.label.editContent')
            : i18n.t('menusidebar.label.newContent')
        }
      />
      <div className="card card-warning">
        <div className="card-header">
          <h3 className="card-title">Content Management</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Title</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="title"
                      name="title"
                      type="title"
                      // placeholder={title}
                      onChange={(eText) => setTitles(eText.target.value)}
                      value={titles}
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Type</label>
                  <select
                    className="form-control"
                    value={currentType}
                    onChange={(e) => handleSelectedContentType(e)}
                  >
                    {statusType.map((opt, i) => (
                      <option key={i}>{opt['type_name']}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Language</label>
                  <select
                    className="form-control"
                    value={currentLang}
                    onChange={(e) => handleSelectedLang(e)}
                  >
                    {language.map((opt, i) => (
                      <option key={i}>{opt['lang_name']}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="exampleInputFile">Image & Thumbnail</label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        name="file"
                        className="custom-file-input"
                        id="file"
                        onChange={(e) => handleFileThumbnail(e)}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        Choose file
                      </label>
                    </div>
                    <div className="input-group-append">
                      <span className="input-group-text">Upload</span>
                    </div>
                  </div>
                  {imagePreviewThumb != null ? (
                    <div className="">
                      <div className="">
                        <img
                          className="mt-5 col-sm-6"
                          alt=""
                          src={`${imagePreviewThumb}`}
                        />
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="exampleInputFile">Gallery</label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        name="file"
                        className="custom-file-input"
                        id="file"
                        multiple
                        onChange={(e) => handleFileGallery(e)}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        Choose file
                      </label>
                    </div>
                    <div className="input-group-append">
                      <span className="input-group-text">Upload</span>
                    </div>
                  </div>
                  {/* {imagePreviewThumb != null ? (
                    <div className="">
                      <div className="">
                        <img
                          className="mt-5 col-sm-6"
                          alt=""
                          src={`${imagePreviewThumb}`}
                        />
                      </div>
                    </div>
                  ) : (
                    <div />
                  )} */}
                </div>
              </div>
              <div className="col-sm-6" />
            </div>
            <div className="col">
              <div className="form-group">
                <label>Description</label>
                <RichTextEditor
                  initialValue={contentData}
                  getValue={getContent}
                />
              </div>
            </div>
            <div className="col-sm-1">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateNewBisnis;
