/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/alt-text */

import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import i18n from '@app/utils/i18n';
import {useLocation} from 'react-router-dom';
import {log} from 'console';
import {Endpoint} from '@app/services/endPoint';

import * as LiniBisnisServices from '../../../services/controller/lini-bisnis/fetch_lini_bisnis';

const DetailProperty = () => {
  const [property_name, setproperty_name] = useState('');
  const [description, setdescription] = useState('');
  const [contact, setcontact] = useState('');
  const [status_id, setstatus_id] = useState('');
  const [lang_id, setlang_id] = useState('');
  const [location, setlocation] = useState('');
  const [date_create, setdate_create] = useState('');
  const [image, setimage] = useState('');

  const parameter = useLocation();
  const dataParam =
    typeof parameter !== 'undefined' && parameter != null
      ? parameter.state.cid
      : null;

  const fetchNewsDetail = async () => {
    try {
      const paramCid = dataParam.contentId;
      const response = await LiniBisnisServices.getDetail(paramCid);
      console.log('log-response', response);
      if (response != null) {
        const propertyName =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.property_name
            : '';
        const image =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.image
            : '';
        const imageBody =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? image.thumbnail_path
            : '';
        const deskripsi =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.description
            : '';

        const kontak =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.contact
            : '';
        const lokasi =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.location
            : '';
        const date_created =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.date_create
            : '';
        const status =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.status_id
            : '';
        const lang =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.lang_id
            : '';

        setproperty_name(propertyName);
        setdescription(deskripsi);
        setcontact(kontak);
        setstatus_id(status);
        setlang_id(lang);
        setlocation(lokasi);
        setdate_create(date_created);
        setimage(imageBody);
      }
    } catch (error) {
      console.log(error);
      setproperty_name('');
      setdescription('');
      setcontact('');
      setstatus_id('');
      setlang_id('');
      setlocation('');
      setdate_create('');
    }
  };

  useEffect(() => {
    fetchNewsDetail();
  }, []);

  return (
    <div>
      {/* <ContentHeader title={`${i18n.t('menusidebar.label.news')} Detail`} /> */}
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body pb-5 ml-5 mr-5 row">
              {image !== '' && image !== null ? (
                <img
                  src={Endpoint.BASE_URL_IMAGE + image}
                  style={{width: '20%'}}
                />
              ) : (
                <div />
              )}

              <div className="pl-5 mt-4">
                <h5>{property_name}</h5>
                <h5>{location}</h5>
                <h5>{contact}</h5>
                <div dangerouslySetInnerHTML={{__html: description}} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DetailProperty;
