/* eslint-disable no-console */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import {Endpoint, header_general} from '@app/services/endPoint';
import axios from 'axios';
import {toast} from 'react-toastify';
import {json} from 'stream/consumers';

export const getAboutUsType = async () => {
  const param = 1;
  const response = await axios({
    method: 'GET',
    headers: header_general,
    url: `${Endpoint.API_SERVICE}${Endpoint.aboutUsType}?e=${param}`
  });
  console.log('log-response', response);

  const status =
    typeof response !== 'undefined' && response != null ? response.status : 404;
  if (status == 200) {
    const result =
      typeof response !== 'undefined' && response != null
        ? response.data
        : null;
    const message =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.message
        : '';
    const statusCode =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.code
        : '';
    console.log('here-status: ', statusCode);

    if (statusCode == 0) {
      const data =
        typeof result !== 'undefined' && result != null && result != ''
          ? result.data
          : '';
      return data;
    }
    return null;
  }
  return null;
};

export const getDataAbout = async (param: any) => {
  const parameter =
    typeof param != 'undefined' && param != null && param != '' ? param : '';
  const response = await axios({
    method: 'GET',
    headers: header_general,
    url: `${Endpoint.API_SERVICE}${Endpoint.aboutUs}?t=${parameter}`
  });
  console.log('log-response', response);

  const status =
    typeof response !== 'undefined' && response != null ? response.status : 404;
  if (status == 200) {
    const result =
      typeof response !== 'undefined' && response != null
        ? response.data
        : null;
    const message =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.message
        : '';
    const statusCode =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.code
        : '';
    console.log('here-status: ', statusCode);

    if (statusCode == 0) {
      const data =
        typeof result !== 'undefined' && result != null && result != ''
          ? result.data
          : '';
      return data;
    }
    return null;
  }
  return null;
};

export const getDetail = async (param: any) => {
  const parameter =
    typeof param != 'undefined' && param != null && param != '' ? param : '';
  const response = await axios({
    method: 'GET',
    headers: header_general,
    url: `${Endpoint.API_SERVICE}${Endpoint.aboutUstDetail}?cid=${parameter}`
  });
  const status =
    typeof response !== 'undefined' && response != null ? response.status : 404;
  if (status == 200) {
    const result =
      typeof response !== 'undefined' && response != null
        ? response.data
        : null;
    const message =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.message
        : '';
    const statusCode =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.code
        : '';
    console.log('here-status: ', response);

    if (statusCode == 0) {
      const data =
        typeof result !== 'undefined' && result != null && result != ''
          ? result.data
          : '';
      return data;
    }
    return null;
  }
  return null;
};
