/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import i18n from '@app/utils/i18n';
import {useNavigate} from 'react-router-dom';
import CustomActionButton from '@app/components/media/CustomActionButton';
import * as MediaService from '../../../services/controller/content/media/fetch_content_data';

const KisahKami = () => {
  const [kisah, setkisah] = useState([]);
  const navigate = useNavigate();

  const fetchKisah = async () => {
    try {
      const paramType = '3';
      const response = await MediaService.getDataMedia(paramType);
      // console.log('log-response-inner-type', response);
      if (response != null) {
        setkisah(response);
      }
    } catch (error) {
      setkisah([]);
    }
  };

  useEffect(() => {
    fetchKisah();
  }, []);

  return (
    <div>
      <ContentHeader title={i18n.t('menusidebar.label.kisah')} />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body p-0">
              <table className="table table-striped projects">
                <thead>
                  <tr>
                    {/* <th className="text-center">Tanggal</th> */}
                    <th className="text-center">Tanggal Publikasi</th>
                    <th className="text-center">Judul</th>
                    <th className="text-center">Sub-Judul</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {kisah.map((news, i) => (
                    <tr key={i}>
                      {/* <td className="text-left">{news['date_create']}</td> */}
                      <td className="text-left">{news['date_publish']}</td>
                      <td className="text-left">{news['title']}</td>
                      <td className="text-left">{news['sub_title']}</td>
                      <td>
                        <CustomActionButton contentId={news['content_id']} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card-footer"> </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default KisahKami;
