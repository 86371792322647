/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import i18n from '@app/utils/i18n';
import {useNavigate} from 'react-router-dom';
import CustomActionButton from '@app/components/about/CustomActionButton';
import * as AboutUsServices from '../../../services/controller/content/about_us/fetch_about_us_data';

const SejarahPerusahaan = () => {
  const [sejarah, setSejarah] = useState([]);
  const navigate = useNavigate();

  const toDetail = (contentId: any) => {
    navigate('/detail-news', {state: {cid: contentId}});
  };

  const toEdit = (contentId: any) => {
    navigate('/edit-news', {state: {cid: contentId}});
  };

  const fetchSejarah = async () => {
    try {
      const paramType = '2';
      const response = await AboutUsServices.getDataAbout(paramType);
      // console.log('log-response-inner-type', response);
      if (response != null) {
        setSejarah(response);
      }
    } catch (error) {
      setSejarah([]);
    }
  };

  useEffect(() => {
    fetchSejarah();
  }, []);
  return (
    <div>
      <ContentHeader title={i18n.t('menusidebar.label.history')} />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body p-0">
              <table className="table table-striped projects">
                <thead>
                  <tr>
                    <th className="text-center">Tanggal</th>
                    <th className="text-center">Tahun</th>
                    <th className="text-center">Deskripsi</th>
                    {/* <th className="text-center">Kontent</th> */}
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sejarah.map((history, i) => (
                    <tr key={i}>
                      <td className="text-left">{history['date_create']}</td>
                      <td className="text-center">{history['year']}</td>
                      <td className="text-left">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: history['description']
                          }}
                        />
                      </td>
                      {/* <td className="text-left">
                        <div
                          dangerouslySetInnerHTML={{__html: news['content']}}
                        />
                      </td> */}
                      <td className="text-left">
                        <CustomActionButton contentId={history['content_id']} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card-footer"> </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SejarahPerusahaan;
