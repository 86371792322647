import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import Register from '@modules/register/Register';
import ForgetPassword from '@modules/forgot-password/ForgotPassword';
import RecoverPassword from '@modules/recover-password/RecoverPassword';
import {useWindowSize} from '@app/hooks/useWindowSize';
import {calculateWindowSize} from '@app/utils/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {setWindowSize} from '@app/store/reducers/ui';

import Dashboard from '@pages/Dashboard';
import SubMenu from '@pages/SubMenu';
import Profile from '@pages/profile/Profile';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import Berita from './pages/media/news/Berita';
import SiaranPers from './pages/media/siaran-pers/SiaranPers';
import KisahKami from './pages/media/kisah-kami/KisahKami';
import Galeri from './pages/media/galeri/Galeri';
import Penguman from './pages/media/pengumuman/Penguman';
import OurBusiness from './pages/Business/BusinessKami';
import UserManagement from './pages/User/UserManagement';
import UserManagementForm from './pages/User/form-user';
import ContentManagement from './pages/media/AddContent';
import SejarahPerusahaan from './pages/Tentang Kami/sejarah/sejarah-perusahaan';
import DewanDireksi from './pages/Tentang Kami/dewan/direksi/board-of-direction';
import DewanKomisaris from './pages/Tentang Kami/dewan/komisaris/board-of-commissioner';
import ContentManagementAboutUs from './pages/Tentang Kami/AddContent';
import Detail from './pages/media/news/Detail';
import ManageGallery from './pages/media/galeri/AddContent';
import DetailTentangKami from './pages/Tentang Kami/DetailTentangKami';
import Penghargaan from './pages/Tentang Kami/awards/penghargaan-awards';
import StrukturOrganisasi from './pages/Tentang Kami/struktur-organisasi/struktur-organisasi';
import ICT from './pages/Business/ict/ict';
import Infrastruktur from './pages/Business/infrastruktur/infrastruktur';
import Property from './pages/Business/property/property';
import Utilitas from './pages/Business/utilitas/utilitas';
import AddNewProperties from './pages/Business/property/AddNewProperties';
import DetailProperty from './pages/Business/property/DetailProperty';
import CreateNewBisnis from './pages/Business/CreateNewBisnis';
import DetailBusiness from './pages/Business/detail-business/DetailBusiness';
import RagamAktifitas from './pages/media/variety-of-activities/RagamAktifitas';
import CreateAcivity from './pages/media/variety-of-activities/CreateActivity';

const App = () => {
  const windowSize = useWindowSize();
  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const dispatch = useDispatch();

  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
  }, [windowSize]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/register" element={<PublicRoute />}>
          <Route path="/register" element={<Register />} />
        </Route>
        <Route path="/forgot-password" element={<PublicRoute />}>
          <Route path="/forgot-password" element={<ForgetPassword />} />
        </Route>
        <Route path="/recover-password" element={<PublicRoute />}>
          <Route path="/recover-password" element={<RecoverPassword />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Main />}>
            <Route
              path="/about-us/company-history"
              element={<SejarahPerusahaan />}
            />
            <Route path="/detail" element={<Detail />} />
            <Route path="/about-us/add-user" element={<UserManagementForm />} />
            <Route path="/about-us/history" element={<Penguman />} />
            <Route path="/about-us/visi-&-misi" element={<Penguman />} />
            <Route
              path="/about-us/boards-of-directors"
              element={<DewanDireksi />}
            />
            <Route
              path="/about-us/boards-of-coummissioners"
              element={<DewanKomisaris />}
            />
            <Route
              path="/about-us/structure"
              element={<StrukturOrganisasi />}
            />
            <Route
              path="/about-us/awards-and-certification"
              element={<Penghargaan />}
            />
            <Route path="/user/user-management" element={<UserManagement />} />
            {/* <Route path="/gallery" element={<Galeri />} /> */}
            {/* <Route path="/announcement" element={<Penguman />} /> */}
            <Route path="/media/announcement" element={<Penguman />} />
            <Route path="/media/story" element={<KisahKami />} />
            <Route path="/media/broadcast" element={<SiaranPers />} />
            <Route path="/media/news" element={<Berita />} />
            <Route path="/media/new-content" element={<ContentManagement />} />
            <Route
              path="/media/variety-of-activities"
              element={<RagamAktifitas />}
            />
            <Route
              path="/media/create-activities"
              element={<CreateAcivity />}
            />
            <Route path="/business" element={<OurBusiness />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/gallery/add-content" element={<ManageGallery />} />
            <Route path="/about-us/detail" element={<DetailTentangKami />} />
            <Route path="/news/edit-content" element={<ContentManagement />} />
            <Route
              path="/about-us/create-about-us"
              element={<ContentManagementAboutUs />}
            />
            <Route
              path="/about-us/edit-data"
              element={<ContentManagementAboutUs />}
            />
            <Route path="/lini-bisnis/ict" element={<ICT />} />
            <Route
              path="/lini-bisnis/infrastruktur"
              element={<Infrastruktur />}
            />
            <Route path="/lini-bisnis/property" element={<Property />} />
            <Route path="/lini-bisnis/utility" element={<Utilitas />} />
            <Route
              path="/lini-bisnis/property/add-new"
              element={<AddNewProperties />}
            />
            <Route
              path="/lini-bisnis/create-new"
              element={<CreateNewBisnis />}
            />
            <Route
              path="/lini-bisnis/property/detail"
              element={<DetailProperty />}
            />
            <Route path="/lini-bisnis/detail" element={<DetailBusiness />} />
            <Route path="/" element={<Dashboard />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </BrowserRouter>
  );
};

export default App;
