/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {ContentHeader} from '@components';
import {Form, InputGroup} from 'react-bootstrap';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {PfButton} from '@profabric/react-components';

const UserManagementForm = () => {
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [isFacebookAuthLoading, setFacebookAuthLoading] = useState(false);
  const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation();

  const login = async (
    email: string,
    password: string,
    name: string,
    birth: string,
    address: string
  ) => {
    try {
      setAuthLoading(true);
      //   const token = await AuthService.loginByAuth(email, password);
      //   toast.success('Login is succeed!');
      //   setAuthLoading(false);
      //   dispatch(loginUser(token));
      //   navigate('/');
    } catch (error: any) {
      setAuthLoading(false);
      toast.error(error.message || 'Failed');
    }
  };

  const {handleChange, values, handleSubmit, touched, errors} = useFormik({
    initialValues: {
      email: '',
      password: '',
      name: '',
      birth: '',
      address: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required'),
      name: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required'),
      birth: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required'),
      address: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required')
    }),
    onSubmit: (values) => {
      login(
        values.email,
        values.password,
        values.name,
        values.birth,
        values.address
      );
    }
  });

  const optionsState = async () => {};

  return (
    <div>
      <ContentHeader title="" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Pengaturan User</h3>
              <div className="card-tools">
                <form onSubmit={handleSubmit}>
                  <div className="btn btn-tool">
                    <PfButton
                      block
                      type="submit"
                      loading={isAuthLoading}
                      disabled={isFacebookAuthLoading || isGoogleAuthLoading}
                    >
                      {t<string>('login.button.signIn.label')}
                    </PfButton>
                  </div>
                </form>
              </div>
            </div>
            <div className="container-fluid m-5">
              <div className="row">
                <div className="col-lg-3 col-6">
                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>User Account</h3>

                      <form onSubmit={handleSubmit}>
                        <div className="card-body">
                          <div className="mb-3">
                            <label>Email</label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="email"
                                name="email"
                                type="email"
                                placeholder=""
                                onChange={handleChange}
                                value={values.email}
                                isValid={touched.email && !errors.email}
                                isInvalid={touched.email && !!errors.email}
                              />
                              {touched.email && errors.email ? (
                                <Form.Control.Feedback type="invalid">
                                  {errors.email}
                                </Form.Control.Feedback>
                              ) : (
                                <InputGroup.Append>
                                  <InputGroup.Text>
                                    <i className="fas fa-envelope" />
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              )}
                            </InputGroup>
                          </div>
                          <div className="mb-3">
                            <label>Password</label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="password"
                                name="password"
                                type="password"
                                placeholder=""
                                onChange={handleChange}
                                value={values.password}
                                isValid={touched.password && !errors.password}
                                isInvalid={
                                  touched.password && !!errors.password
                                }
                              />
                              {touched.password && errors.password ? (
                                <Form.Control.Feedback type="invalid">
                                  {errors.password}
                                </Form.Control.Feedback>
                              ) : (
                                <InputGroup.Append>
                                  <InputGroup.Text>
                                    <i className="fas fa-key" />
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              )}
                            </InputGroup>
                          </div>
                          <div>
                            <div className="form-group">
                              <label>User Role</label>
                              <select className="custom-select">
                                <option>Super Admin</option>
                                <option>Admin</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="icon">
                      <i className="ion ion-email" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="small-box bg-warning">
                    <div className="inner">
                      <h3>User Information</h3>

                      <form onSubmit={handleSubmit}>
                        <div className="card-body">
                          <div className="mb-3">
                            <label>Name</label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="name"
                                name="name"
                                type="text"
                                placeholder=""
                                onChange={handleChange}
                                value={values.name}
                                isValid={touched.name && !errors.name}
                                isInvalid={touched.name && !!errors.name}
                              />
                              {touched.name && errors.name ? (
                                <Form.Control.Feedback type="invalid">
                                  {errors.name}
                                </Form.Control.Feedback>
                              ) : (
                                <InputGroup.Append>
                                  <InputGroup.Text>
                                    <i className="fas fa-user" />
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              )}
                            </InputGroup>
                          </div>
                          <div className="mb-3">
                            <label>Date of Birth</label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="birth"
                                name="birth"
                                type="date"
                                placeholder=""
                                onChange={handleChange}
                                value={values.birth}
                                isValid={touched.birth && !errors.birth}
                                isInvalid={touched.birth && !!errors.birth}
                              />
                              {touched.birth && errors.birth ? (
                                <Form.Control.Feedback type="invalid">
                                  {errors.birth}
                                </Form.Control.Feedback>
                              ) : (
                                <InputGroup.Append>
                                  <InputGroup.Text>
                                    <i className="fas fa-calendar" />
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              )}
                            </InputGroup>
                          </div>
                          <div className="mb-3">
                            <label>Address</label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="address"
                                name="address"
                                type="text"
                                placeholder=""
                                onChange={handleChange}
                                value={values.address}
                                isValid={touched.address && !errors.address}
                                isInvalid={touched.address && !!errors.address}
                              />
                              {touched.address && errors.address ? (
                                <Form.Control.Feedback type="invalid">
                                  {errors.address}
                                </Form.Control.Feedback>
                              ) : (
                                <InputGroup.Append>
                                  <InputGroup.Text>
                                    <i className="fas fa-home" />
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              )}
                            </InputGroup>
                          </div>
                        </div>
                      </form>
                      <div className="icon">
                        <i className="ion ion-information" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              {/* <div className="card-footer">Footer</div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserManagementForm;
