/* eslint-disable no-alert */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/react-in-jsx-scope */

import {ContentHeader} from '@app/components';
import RichTextEditor from '@app/components/RichTextEditor';
import {useFormik} from 'formik';
import {SetStateAction, useEffect, useMemo, useRef, useState} from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {PfButton} from '@profabric/react-components';
import {useLocation, useNavigate} from 'react-router-dom';
import i18n from '@app/utils/i18n';
import {log} from 'console';
import {Endpoint} from '@app/services/endPoint';

import * as Yup from 'yup';
import * as aboutUsService from '../../services/controller/content/about_us/fetch_about_us_data';
import * as languages from '../../services/controller/language/fetch_laguage';
import * as AboutusServicePost from '../../services/controller/content/about_us/post_about_data';

const ContentManagementAboutUs = () => {
  const [language, setLanguage] = useState([]);
  const [contentType, setContentDataType] = useState([]);

  const [titles, setTitles] = useState('');
  const [optionsLang, setOptionsLang] = useState('');
  const [optionsContent, setOptionsContent] = useState('');
  const [departmentData, setDepartment] = useState('');
  const [filesThumb, setFilesThumb] = useState<any | null>(null);
  const [contentData, setContentData] = useState('');
  const [years, setYears] = useState('');

  const [imagePreviewThumb, setImagePreviewThumb] = useState<any | null>(null);
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [currentType, setCurrentType] = useState('Pilih tipe media');
  const [currentLang, setCurrentLang] = useState('Pilih tipe media');

  const navigate = useNavigate();
  const location = useLocation();

  const cid =
    typeof location.state !== 'undefined' && location.state != null
      ? location.state.cid.contentId
      : null;
  const dataType =
    typeof location.state !== 'undefined' && location.state != null
      ? location.state.type
      : null;
  const page =
    typeof location.state !== 'undefined' && location.state != null
      ? location.state.pages
      : null;

  const slugify = (str: any) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

  const getContent = (value: any) => {
    setContentData(value);
  };

  const handleFileThumbnail = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const url = URL.createObjectURL(file);
      setImagePreviewThumb(url);
      setFilesThumb(file);
      console.log('log-data-image', file);
    }
  };

  const handleSelectedContentType = (e: any) => {
    console.log('selected', e.target.value);
    const selectedValue =
      typeof e !== 'undefined' && e != '' && e != null ? e.target.value : '';
    setCurrentType(selectedValue);
    let index = 0;
    let contentTypes = '';
    for (index = 0; index < contentType.length; index++) {
      const element = contentType[index];
      const value = element;
      console.log(value);
      if (value['type_name'] == selectedValue) {
        console.log('here');
        contentTypes = value['about_type_id'];
      }
    }
    console.log('selected type', contentTypes);
    setOptionsContent(contentTypes);
  };

  const handleSelectedLang = (e: any) => {
    console.log('selected', e.target.value);
    const selectedValue =
      typeof e !== 'undefined' && e != '' && e != null ? e.target.value : '';
    setCurrentLang(selectedValue);
    let index = 0;
    let lang = '';
    for (index = 0; index < language.length; index++) {
      const element = language[index];
      const value = element;
      console.log(value['lang_name']);
      if (value['lang_name'] == selectedValue) {
        lang = value['lang_id'];
      }
    }
    console.log('selected language', lang);
    setOptionsLang(lang);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if ((titles.length == 0 && optionsContent == '') || optionsContent == '1') {
      alert(`Form must be field`);
    } else if (titles.length == 0) {
      alert(`Must be 10 characters or more`);
    } else if (optionsContent == '' || optionsContent == '1') {
      alert(`Please Choose Content Type`);
    } else if (optionsLang == '' || optionsContent == '1') {
      alert(`Please Choose Language`);
    } else {
      log('here');
      let param = {};

      if (dataType == 'edit') {
        param = {
          content_id: cid,
          title: titles,
          department: departmentData,
          about_type_id: optionsContent,
          lang_id: optionsLang,
          year: years,
          slug: slugify(titles),
          image_thumbnail: filesThumb,
          description: contentData
        };
      } else {
        param = {
          title: titles,
          department: departmentData,
          about_type_id: optionsContent,
          lang_id: optionsLang,
          year: years,
          slug: slugify(titles),
          image_thumbnail: filesThumb,
          description: contentData
        };
      }

      postContent(param);
    }
  };

  const postContent = async (param: any) => {
    try {
      log('data', param);
      let result = null;
      if (dataType == 'edit') {
        result = await AboutusServicePost.editContent(param);
        if (result.code == 0) {
          navigate(-1);
        }
      } else {
        setAuthLoading(true);
        result = await AboutusServicePost.sendContent(param);
        console.log('log-result-post', result['about_type_id']);
        setAuthLoading(false);
        if (result.code == 0) {
          navigate(-1);
        }
        setAuthLoading(false);
      }
    } catch (error: any) {
      // toast.error(error.message);
      setAuthLoading(false);
    }
  };

  const fetchContentType = async () => {
    try {
      const response = await aboutUsService.getAboutUsType();
      console.log('log-response-inner-type', dataType);
      if (response != null) {
        setContentDataType(response);
      }
    } catch (error) {
      setContentDataType([]);
    }
  };

  const fetchContentLang = async () => {
    try {
      const response = await languages.getLanguage();
      console.log('log-response-inner-language', response);
      if (response != null) {
        setLanguage(response);
      }
    } catch (error) {
      setLanguage([]);
    }
  };

  const fetchDetail = async () => {
    try {
      let index = 0;
      const paramCid = cid;
      const pages = page;
      let aboutContentId = '';
      const response = await aboutUsService.getDetail(paramCid);
      // console.log('log-response-inner-type', response['content']);
      if (response != null) {
        const title =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.title
            : '';
        const subtitle =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.sub_title
            : '';
        const image =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.image
            : '';
        const imageBody =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? image.thumbnail_path
            : '';
        const content =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.description
            : '';

        const typeContent =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.about_type_id
            : '';

        const year =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.year
            : '';
        const language =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.lang_id
            : '';
        const department =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.department
            : '';

        for (index = 0; index < contentType.length; index++) {
          const element = contentType[index];
          const value = element;
          console.log('here-value-', typeContent);
          if (value['about_type_id'] == typeContent) {
            console.log('here-value-02', value['type_name']);
            aboutContentId = value['type_name'];
          }
        }

        setOptionsLang(language);
        setContentData(content);
        setTitles(title);
        // setOptionsContent(contentType);
        setOptionsContent(aboutContentId);
        setCurrentType(aboutContentId);
        setOptionsLang(language);
        setYears(year);
        setFilesThumb(imageBody);
        setImagePreviewThumb(imageBody);
        setDepartment(department);
      }
    } catch (error) {
      setContentData('');
    }
  };

  useEffect(() => {
    fetchContentType();
    fetchContentLang();
    if (dataType == 'edit') {
      fetchDetail();
    }
  }, []);

  return (
    <div>
      <ContentHeader
        title={
          dataType == 'edit'
            ? i18n.t('menusidebar.label.editContent')
            : i18n.t('menusidebar.label.newContent')
        }
      />
      <div className="card card-warning">
        <div className="card-header">
          <h3 className="card-title">Content Management</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    {optionsContent == '3' || optionsContent == '4'
                      ? 'Name'
                      : 'Title'}
                  </label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="title"
                      name="title"
                      type="title"
                      placeholder={
                        optionsContent == '3' || optionsContent == '4'
                          ? 'Name'
                          : 'Title'
                      }
                      onChange={(eText) => setTitles(eText.target.value)}
                      value={titles}
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Content Type</label>
                  <select
                    className="form-control"
                    value={currentType}
                    onChange={(e) => handleSelectedContentType(e)}
                  >
                    {contentType.map((opt, i) => (
                      <option key={i}>{opt['type_name']}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Language</label>
                  <select
                    className="form-control"
                    value={currentLang}
                    onChange={(e) => handleSelectedLang(e)}
                  >
                    {language.map((opt, i) => (
                      <option key={i}>{opt['lang_name']}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="exampleInputFile">Image & Thumbnail</label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        name="file"
                        className="custom-file-input"
                        id="file"
                        onChange={(e) => handleFileThumbnail(e)}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        Choose file
                      </label>
                    </div>
                    <div className="input-group-append">
                      <span className="input-group-text">Upload</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {optionsContent == '2' || optionsContent == '6' ? (
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Year</label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        id="year"
                        name="year"
                        type="year"
                        placeholder="Year"
                        onChange={(text) => setYears(text.target.value)}
                        value={years}
                      />
                    </InputGroup>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="col-sm-6">
                {imagePreviewThumb != null ? (
                  <div className="form-group">
                    <img
                      className="mt-5 col-sm-6"
                      alt=""
                      src={Endpoint.BASE_URL_IMAGE + imagePreviewThumb}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            {optionsContent == '3' || optionsContent == '4' ? (
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Department</label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        id="department"
                        name="department"
                        type="department"
                        placeholder="Department"
                        onChange={(text) => setDepartment(text.target.value)}
                        value={departmentData}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {optionsContent != '5' ? (
              <div className="col">
                <div className="form-group">
                  <label>Description</label>
                  <RichTextEditor
                    initialValue={contentData}
                    getValue={getContent}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="col-sm-1">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContentManagementAboutUs;
