/* eslint-disable react/no-danger */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/react-in-jsx-scope */

import React, {useEffect, useState} from 'react';
import i18n from '@app/utils/i18n';
import {ContentHeader} from '@components';
import CustomActionButton from '@app/components/RagamAktifitas/CustomActionButton';
import * as MediaService from '../../../services/controller/content/media/fetch_content_data';

const RagamAktifitas = () => {
  const [ragamAktifitas, setRagamAktifitas] = useState([]);
  const fetchRagamAktifitas = async () => {
    try {
      const paramType = '2';
      const response = await MediaService.getDataRagamAktifitas(paramType);
      // console.log('log-response-inner-type', response);
      if (response != null) {
        setRagamAktifitas(response);
      }
    } catch (error) {
      setRagamAktifitas([]);
    }
  };
  useEffect(() => {
    fetchRagamAktifitas();
  }, []);

  return (
    <div>
      <ContentHeader
        title={i18n.t('menusidebar.label.variety-of-activities')}
      />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            {ragamAktifitas.length < 4 ? (
              <div className="card-header">
                <div className="card-tools">
                  <div className="card-tools">
                    <a href="/media/create-activities" className="btn btn-tool">
                      Add New
                      <i
                        className="fa fa-plus"
                        style={{paddingLeft: 15, paddingRight: 15}}
                      />
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div />
            )}
            <div className="card-body p-0">
              <table className="table table-striped projects">
                <thead>
                  <tr>
                    {/* <th className="text-center">Tanggal</th> */}
                    <th className="text-center">Tanggal Publikasi</th>
                    <th className="text-center">Judul</th>
                    <th className="text-center">Lokasi</th>
                    <th className="text-center">Deskripsi</th>
                    <th className="text-center">Link Pendaftaran</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {ragamAktifitas.map((ragam, i) => (
                    <tr key={i}>
                      <td className="text-left">{ragam.date_release}</td>
                      <td className="text-left">{ragam.title}</td>
                      <td className="text-left">{ragam.place}</td>
                      <td className="text-left">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: ragam.content
                          }}
                        />
                      </td>
                      <td className="text-left">
                        <a href={ragam.register_at}>Registration</a>
                      </td>
                      <td>
                        <CustomActionButton ragamId={ragam.id_ragam} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card-footer"> </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default RagamAktifitas;
