/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import i18n from '@app/utils/i18n';
import {useNavigate} from 'react-router-dom';
import CustomActionButton from '@app/components/about/CustomActionButton';
import * as AboutUsServices from '../../../../services/controller/content/about_us/fetch_about_us_data';

const DewanKomisaris = () => {
  const [komisaris, setKomisaris] = useState([]);
  const navigate = useNavigate();

  const fetchKomisaris = async () => {
    try {
      const paramType = '4';
      const response = await AboutUsServices.getDataAbout(paramType);
      // console.log('log-response-inner-type', response);
      if (response != null) {
        setKomisaris(response);
      }
    } catch (error) {
      setKomisaris([]);
    }
  };

  useEffect(() => {
    fetchKomisaris();
  }, []);
  return (
    <div>
      <ContentHeader title={i18n.t('menusidebar.label.bod')} />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body p-0">
              <table className="table table-striped projects">
                <thead>
                  <tr>
                    <th className="text-center">tanggal</th>
                    <th className="text-center">Nama</th>
                    <th className="text-center">Jabatan</th>
                    <th className="text-center">Deskripsi</th>
                    {/* <th className="text-center">Kontent</th> */}
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {komisaris.map((history, i) => (
                    <tr key={i}>
                      <td className="text-left">{history['date_create']}</td>
                      <td className="text-left">{history['title']}</td>
                      <td className="text-left">{history['department']}</td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: history['description']
                        }}
                      />
                      <td className="">
                        <CustomActionButton contentId={history['content_id']} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card-footer"> </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DewanKomisaris;
