import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {MenuItem} from '@components';
import {PfImage} from '@profabric/react-components';
import styled from 'styled-components';
import {SidebarSearch} from '@app/components/sidebar-search/SidebarSearch';
import i18n from '@app/utils/i18n';

export interface IMenuItem {
  name: string;
  icon?: string;
  path?: string;
  children?: Array<IMenuItem>;
}

export const MENU: IMenuItem[] = [
  {
    name: i18n.t('menusidebar.label.aboutUs'),
    icon: 'fas fa-info nav-icon',
    children: [
      // {
      //   name: i18n.t('menusidebar.label.history'),
      //   icon: 'far fa-circle nav-icon',
      //   path: '/history'
      // },
      // {
      //   name: i18n.t('menusidebar.label.visimisi'),
      //   icon: 'far fa-circle nav-icon',
      //   path: '/visi-&-misi'
      // },
      {
        name: i18n.t('menusidebar.label.newContent'),
        icon: 'far fa-plus-square nav-icon',
        path: '/about-us/create-about-us'
      },
      {
        name: i18n.t('menusidebar.label.history'),
        icon: 'far fa-circle nav-icon',
        path: '/about-us/company-history'
      },
      {
        name: i18n.t('menusidebar.label.bod'),
        icon: 'far fa-circle nav-icon',
        path: '/about-us/boards-of-directors'
      },
      {
        name: i18n.t('menusidebar.label.boc'),
        icon: 'far fa-circle nav-icon',
        path: '/about-us/boards-of-coummissioners'
      },
      {
        name: i18n.t('menusidebar.label.struktur'),
        icon: 'far fa-circle nav-icon',
        path: '/about-us/structure'
      },
      {
        name: i18n.t('menusidebar.label.aac'),
        icon: 'far fa-circle nav-icon',
        path: '/about-us/awards-and-certification'
      }
    ]
  },
  {
    name: i18n.t('menusidebar.label.businessMenu'),
    icon: 'fas fa-copy nav-icon',
    children: [
      {
        name: i18n.t('menusidebar.label.properties'),
        icon: 'far fa-circle nav-icon',
        path: '/lini-bisnis/property'
      },

      {
        name: i18n.t('menusidebar.label.infrastructure'),
        icon: 'far fa-circle nav-icon',
        path: '/lini-bisnis/infrastruktur'
      },

      {
        name: i18n.t('menusidebar.label.utility'),
        icon: 'far fa-circle nav-icon',
        path: '/lini-bisnis/utility'
      },

      {
        name: i18n.t('menusidebar.label.ict'),
        icon: 'far fa-circle nav-icon',
        path: '/lini-bisnis/ict'
      }
    ]
  },
  {
    name: i18n.t('menusidebar.label.media'),
    icon: 'fas fa-newspaper nav-icon',
    children: [
      {
        name: i18n.t('menusidebar.label.newContent'),
        icon: 'far fa-plus-square nav-icon',
        path: '/media/new-content'
      },
      {
        name: i18n.t('menusidebar.label.news'),
        icon: 'far fa-circle nav-icon',
        path: '/media/news'
      },

      {
        name: i18n.t('menusidebar.label.siaran'),
        icon: 'far fa-circle nav-icon',
        path: '/media/broadcast'
      },

      {
        name: i18n.t('menusidebar.label.kisah'),
        icon: 'far fa-circle nav-icon',
        path: '/media/story'
      },
      {
        name: i18n.t('menusidebar.label.pengumuman'),
        icon: 'far fa-circle nav-icon',
        path: '/media/announcement'
      },
      {
        name: i18n.t('menusidebar.label.variety-of-activities'),
        icon: 'far fa-circle nav-icon',
        path: '/media/variety-of-activities'
      },
      {
        name: i18n.t('menusidebar.label.video-tron'),
        icon: 'far fa-circle nav-icon',
        path: '/media/video-tron'
      }
    ]
  },
  {
    name: i18n.t('menusidebar.label.user'),
    icon: 'fas fa-user nav-icon',
    children: [
      {
        name: i18n.t('menusidebar.label.userMgmt'),
        icon: 'far fa-circle nav-icon',
        path: '/user/user-management'
      }
    ]
  }
];

const StyledBrandImage = styled(PfImage)`
  float: left;
  line-height: 0.8;
  margin: -1px 8px 0 6px;
  opacity: 0.8;
  --pf-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23) !important;
`;

const StyledUserImage = styled(PfImage)`
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
`;

const MenuSidebar = () => {
  const user = useSelector((state: any) => state.auth.currentUser);
  const sidebarSkin = useSelector((state: any) => state.ui.sidebarSkin);
  const menuItemFlat = useSelector((state: any) => state.ui.menuItemFlat);
  const menuChildIndent = useSelector((state: any) => state.ui.menuChildIndent);

  return (
    <aside className={`main-sidebar elevation-4 ${sidebarSkin}`}>
      <Link to="/" className="brand-link">
        <StyledBrandImage
          src="/img/logo.png"
          alt="AdminLTE Logo"
          width={33}
          height={33}
          rounded
        />
        <span className="brand-text font-weight-light">Jakpro CMS</span>
      </Link>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <StyledUserImage
              src={user.picture}
              fallbackSrc="/img/default-profile.png"
              alt="User"
              width={34}
              height={34}
              rounded
            />
          </div>
          <div className="info">
            <Link to="/" className="d-block">
              {user.email}
            </Link>
          </div>
        </div>

        <div className="form-inline">
          <SidebarSearch />
        </div>

        <nav className="mt-2" style={{overflowY: 'hidden'}}>
          <ul
            className={`nav nav-pills nav-sidebar flex-column${
              menuItemFlat ? ' nav-flat' : ''
            }${menuChildIndent ? ' nav-child-indent' : ''}`}
            role="menu"
          >
            {MENU.map((menuItem: IMenuItem) => (
              <MenuItem
                key={menuItem.name + menuItem.path}
                menuItem={menuItem}
              />
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default MenuSidebar;
