/* eslint-disable camelcase */
const Endpoint = {
  // DEV
  // API_SERVICE: 'https://mustseeum.com/jakpro/',
  // BASE_URL_IMAGE: 'https://mustseeum.com/',

  // PROD
  API_SERVICE: 'https://jakpro.co.id/jakpro/',
  BASE_URL_IMAGE: 'https://jakpro.co.id',

  // user
  login: 'user/login',
  newUser: 'user/create_user',
  updateUser: 'user/update_user',
  userList: 'user/user_list',
  userDetail: 'user/user_detail',

  // content
  newContent: 'content/create_content',
  updateContent: 'content/update_content',
  listContent: 'content/content_list',
  contentDetail: 'content/content_detail',
  aboutUstDetail: 'about_us/content_detail',
  editContent: 'content/update_content',
  contentType: 'content/content_type',
  deleteContent: 'content/delete_content',
  language: 'content/language',

  aboutUs: 'about_us/about_us',
  aboutUsType: 'about_us/about_us_type',
  newAboutUs: 'about_us/create_content_about',
  deleteContentAbout: 'about_us/delete_content',
  updateContentAbout: 'about_us/update_content',

  // properties
  statusProperty: 'property/property_status',
  newProperty: 'property/create_property',
  propertyList: 'property/property_list',
  deleteProperty: 'property/delete_property',
  detailProperty: 'property/property_detail',
  editProperty: 'property/update_property',

  // bisnis
  getBisnis: 'lini_bisnis/bisni_list',
  getBisnisType: 'lini_bisnis/bisnis_type',
  bisnisDetail: 'lini_bisnis/bisnis_detail',
  updateBisnis: 'lini_bisnis/update_bisnis',
  deleteBisnis: 'lini_bisnis/delete_bisnis',
  newBusiness: 'lini_bisnis/create_gallery',
  galleryDetail: 'lini_bisnis/gallery_detail',
  deleteBusiness: 'lini_bisnis/delete_bisnis',

  // Media
  ragamAktifitas: 'ragam_aktifitas/ragam',
  createRagamAktifitas: 'ragam_aktifitas/create_ragam_aktifitas',
  deleteRagamAktifitas: 'ragam_aktifitas/delete_content'
};

const header_multipart = {
  'Content-Type': 'multipart/form-data',
  accept: 'application/json'
};

const header_general = {
  'Content-Type': 'application/json',
  accept: 'application/json'
};

export {Endpoint, header_multipart, header_general};
