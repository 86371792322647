/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import i18n from '@app/utils/i18n';
import {useNavigate} from 'react-router-dom';
import CustomActionButton from '@app/components/lini-bisnis/CustomActionButton';
import * as LiniBisnisServices from '../../../services/controller/lini-bisnis/fetch_lini_bisnis';

const Utilitas = () => {
  const [util, setUtil] = useState([]);

  const fetchUtil = async () => {
    try {
      const paramType = '2';
      const response = await LiniBisnisServices.getBisnis(paramType);
      console.log('log-response', response);
      if (response != null) {
        setUtil(response);
      }
    } catch (error) {
      setUtil([]);
    }
  };

  useEffect(() => {
    fetchUtil();
  }, []);

  return (
    <div>
      <ContentHeader title={i18n.t('menusidebar.label.utility')} />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <div className="card-tools">
                <div className="card-tools">
                  <a href="/lini-bisnis/create-new" className="btn btn-tool">
                    Create New
                    <i
                      className="fa fa-plus"
                      style={{paddingLeft: 15, paddingRight: 15}}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <table className="table table-striped projects">
                <thead>
                  <tr>
                    <th className="text-center">Tanggal</th>
                    <th className="text-center">Judul</th>
                    <th className="text-center">Description</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                {util != null ? (
                  <tbody>
                    {util.map((util, i) => (
                      <tr key={i}>
                        <td className="text-left">{util['date_create']}</td>
                        <td className="text-left">{util['lini_title']}</td>
                        <td className="text-left">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: util['lini_description']
                            }}
                          />
                        </td>
                        <td>
                          <CustomActionButton contentId={util['lini_id']} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <div className="card-body">Data Not Found</div>
                )}
              </table>
            </div>
            <div className="card-footer"> </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Utilitas;
