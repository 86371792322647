/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/alt-text */

import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import i18n from '@app/utils/i18n';
import {useLocation} from 'react-router-dom';
import {log} from 'console';
import {Endpoint} from '@app/services/endPoint';

import * as LiniBisnisServices from '../../../services/controller/lini-bisnis/fetch_lini_bisnis';

const DetailBusiness = () => {
  const [titile, setTitle] = useState('');
  const [description, setdescription] = useState('');
  const [contact, setcontact] = useState('');
  const [location, setlocation] = useState('');
  const [image, setimage] = useState('');
  const [gallery, setGallery] = useState([]);
  const [typeId, setTypeId] = useState('');
  const [langId, setLangId] = useState('');
  const [date, setDate] = useState('');

  const parameter = useLocation();
  const dataParam =
    typeof parameter !== 'undefined' && parameter != null
      ? parameter.state.cid
      : null;

  const fetchNewsDetail = async () => {
    try {
      const paramCid = dataParam.contentId;
      const response = await LiniBisnisServices.getGalleryDetail(paramCid);
      console.log('log-response', response);
      if (response != null) {
        const title =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.lini_title
            : '';
        const image =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.image
            : '';
        const imageBody =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? image.thumbnail_path
            : '';
        const imageGallery =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? image.gallery
            : '';
        const deskripsi =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.lini_description
            : '';

        const date_created =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.date_create
            : '';
        const type =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.lini_type_id
            : '';
        const lang =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.lang_id
            : '';

        setTitle(title);
        setdescription(deskripsi);
        setimage(imageBody);
        setDate(date);
        setGallery(imageGallery);
      }
    } catch (error) {
      setTitle('');
      setdescription('');
      setimage('');
      setDate('');
      setGallery([]);
    }
  };

  useEffect(() => {
    fetchNewsDetail();
  }, []);

  return (
    <div>
      {/* <ContentHeader title={`${i18n.t('menusidebar.label.news')} Detail`} /> */}
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body pb-5 ml-5 mr-5 row">
              {image !== '' && image !== null ? (
                <img
                  src={Endpoint.BASE_URL_IMAGE + image}
                  style={{width: '20%'}}
                />
              ) : (
                <div />
              )}

              <div className="pl-5 mt-4">
                <h5>{titile}</h5>
                <h5>{location}</h5>
                <h5>{contact}</h5>
                <div dangerouslySetInnerHTML={{__html: description}} />
                <h5 className="bold text-red">Gallery</h5>
                <div className="card-body">
                  <div className="row">
                    {/* <div className="col-sm-2">
                      <a
                        href="https://via.placeholder.com/1200/FFFFFF.png?text=1"
                        data-toggle="lightbox"
                        data-title="sample 1 - white"
                        data-gallery="gallery"
                      >
                        <img
                          src="https://via.placeholder.com/300/FFFFFF?text=1"
                          className="img-fluid mb-2"
                          alt="white sample"
                        />
                      </a>
                    </div> */}
                    {gallery.map((g, i) => (
                      <div className="col-sm-2" key={i}>
                        <a
                          // href="https://via.placeholder.com/1200/000000.png?text=2"
                          href={Endpoint.BASE_URL_IMAGE + g['file_path']}
                          data-toggle="lightbox"
                          data-title="sample 2 - black"
                          data-gallery="gallery"
                        >
                          <img
                            src={Endpoint.BASE_URL_IMAGE + g['file_path']}
                            // src="https://via.placeholder.com/300/000000?text=2"
                            className="img-fluid mb-2"
                            alt="black sample"
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DetailBusiness;
