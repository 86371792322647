/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, {useRef} from 'react';
import JoditEditor from 'jodit-react';

const config = {
  tooltip: 'Wrap selection in tag',
  list: ['h1', 'h2', 'h3']
};

const RichTextEditor = ({
  initialValue,
  getValue
}: {
  initialValue: any;
  getValue: any;
}) => {
  const editor = useRef(null);

  return (
    <JoditEditor
      ref={editor}
      value={initialValue}
      // config={config}
      // tabIndex={3}
      onBlur={(newContent) => getValue(newContent)}
      onChange={(newContent) => getValue(newContent)}
    />
  );
};

export default RichTextEditor;
