/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import i18n from '@app/utils/i18n';
import {useNavigate} from 'react-router-dom';
import CustomActionButton from '@app/components/media/CustomActionButton';
import * as MediaService from '../../../services/controller/content/media/fetch_content_data';

const Berita = () => {
  const [berita, setBerita] = useState([]);
  const navigate = useNavigate();

  const toDetail = (contentId: any) => {
    navigate('/detail-news', {state: {cid: contentId}});
  };

  const toEdit = (contentId: any) => {
    navigate('/edit-news', {state: {cid: contentId}});
  };

  const fetchNews = async () => {
    try {
      const paramType = '1';
      const response = await MediaService.getDataMedia(paramType);
      console.log('log-response-inner-type', response);
      if (response != null) {
        setBerita(response);
      }
    } catch (error) {
      setBerita([]);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <div>
      <ContentHeader title={i18n.t('menusidebar.label.news')} />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            {/* <div className="card-header">
              <h3 className="card-title">{i18n.t('menusidebar.label.news')}</h3>
            </div> */}
            <div className="card-body p-0">
              <table className="table table-striped projects">
                <thead>
                  <tr>
                    {/* <th className="text-center">Tanggal</th> */}
                    <th className="text-center">Tanggal Publikasi</th>
                    <th className="text-center">Judul</th>
                    <th className="text-center">Sub-Judul</th>
                    {/* <th className="text-center">Kontent</th> */}
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {berita.map((news, i) => (
                    <tr key={i}>
                      {/* <td className="text-left">{news['date_create']}</td> */}
                      <td className="text-left">{news['date_publish']}</td>
                      <td className="text-left">{news['title']}</td>
                      <td className="text-left">{news['sub_title']}</td>
                      {/* <td className="text-left">
                        <div
                          dangerouslySetInnerHTML={{__html: news['content']}}
                        />
                      </td> */}
                      <td>
                        <CustomActionButton contentId={news['content_id']} />
                        {/* <a
                          className="btn btn-info btn-sm"
                          // href="/detail-news"
                          onClick={() => {
                            toDetail(news['content_id']);
                          }}
                          style={{
                            marginRight: 5,
                            marginBottom: 5,
                            marginTop: 5
                          }}
                        >
                          <i className="fas fa-eye"> </i>
                          View
                        </a>
                        <a
                          className="btn btn-info btn-sm"
                          onClick={() => {
                            toEdit(news['content_id']);
                          }}
                          style={{
                            marginRight: 5,
                            marginBottom: 5,
                            marginTop: 5
                          }}
                        >
                          <i className="fas fa-pencil-alt"> </i>
                          Edit
                        </a>
                        <a
                          className="btn btn-danger btn-sm"
                          href="#"
                          style={{
                            marginRight: 5,
                            marginBottom: 5,
                            marginTop: 5
                          }}
                        >
                          <i className="fas fa-trash"> </i>
                          Delete
                        </a> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card-footer"> </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Berita;
