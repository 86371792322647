/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-danger */
/* eslint-disable dot-notation */
/* eslint-disable no-console */
import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import i18n from '@app/utils/i18n';
import {useLocation} from 'react-router-dom';
import {log} from 'console';
import {Endpoint} from '@app/services/endPoint';

import * as MediaService from '../../../services/controller/content/media/fetch_content_data';
import * as AboutService from '../../../services/controller/content/about_us/fetch_about_us_data';

const Detail = () => {
  const [conntent, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [subtitle, setSubTitle] = useState('');
  const [description, setDescription] = useState('');
  const [department, setDepartment] = useState('');
  const [imageHeader, setImageHeader] = useState('');
  const [imageBody, setImageBody] = useState('');

  const location = useLocation();
  const dataParam =
    typeof location !== 'undefined' && location != null
      ? location.state.cid
      : null;

  const fetchNewsDetail = async () => {
    try {
      const paramCid = dataParam.contentId;
      const response = await MediaService.getDetail(paramCid);
      // console.log('log-response-inner-type', response['content']);
      if (response != null) {
        const title =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.title
            : '';
        const subtitle =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.sub_title
            : '';
        const image =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.image
            : '';
        const imageBody =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? image.thumbnail_path
            : '';
        const imageHeader =
          typeof image !== 'undefined' && image !== null && image !== 'null'
            ? image.header_path
            : '';
        const content =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.content
            : '';
        const deskripsi =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.description
            : '';
        const department =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.department
            : '';

        setContent(content);
        setTitle(title);
        setSubTitle(subtitle);
        setImageHeader(imageHeader);
        setImageBody(imageBody);
        setDepartment(department);
        setDescription(description);
      }
    } catch (error) {
      setContent('');
    }
  };

  useEffect(() => {
    fetchNewsDetail();
  }, []);

  return (
    <div>
      <ContentHeader title={`${i18n.t('menusidebar.label.news')} Detail`} />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body p-15 ml-5 mr-5">
              {imageBody !== '' && imageBody !== null ? (
                <img
                  className="col p-5"
                  src={Endpoint.BASE_URL_IMAGE + imageBody}
                />
              ) : (
                <div />
              )}

              <div className="pl-5 pr-5">
                <h1>{title}</h1>
                <h5>{subtitle}</h5>
                <div dangerouslySetInnerHTML={{__html: conntent}} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Detail;
