/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */

import {Endpoint, header_multipart} from "@app/services/endPoint";
import axios from "axios";
import { toast } from "react-toastify";

export const sendContent = async (param: any) => {
  // console.log('log-message-data-post', param);
  const response = await axios({
    method: 'POST',
    headers: header_multipart,
    url: `${Endpoint.API_SERVICE}${Endpoint.newProperty}`,
    data: param
  });
  const status =
    typeof response !== 'undefined' && response != null ? response.status : 404;
  console.log('data-response ', response);

  if (status == 200) {
    const result =
      typeof response !== 'undefined' && response != null
        ? response.data
        : null;
    const message =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.message
        : '';
    const statusCode =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.code
        : '';
    // console.log('here-status: ', statusCode);
    if (statusCode == 0) {
      const data =
        typeof result !== 'undefined' && result != null && result != ''
          ? result.data
          : '';
      toast.success(message);
      return data;
    }
    return null;
  }
  return null;
};

export const editContent = async (param: any) => {
  // console.log('log-message-data-post', param);
  const response = await axios({
    method: 'POST',
    headers: header_multipart,
    url: `${Endpoint.API_SERVICE}${Endpoint.editProperty}`,
    data: param
  });
  const status =
    typeof response !== 'undefined' && response != null ? response.status : 404;
  console.log('data-response ', response);

  if (status == 200) {
    const result =
      typeof response !== 'undefined' && response != null
        ? response.data
        : null;
    const message =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.message
        : '';
    const statusCode =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.code
        : '';
    // console.log('here-status: ', statusCode);
    if (statusCode == 0) {
      const data =
        typeof result !== 'undefined' && result != null && result != ''
          ? result.data
          : '';
      toast.success(message);
      return data;
    } 
    toast.error(message);
    return null;
  }
  return null;
};

export const deleteData = async (param: any) => {
  const cid =
    typeof param !== 'undefined' && param !== null ? param.contentId : null;
  const body = {
    property_id: cid
  };
  console.log('log-message', cid);

  const response = await axios({
    method: 'POST',
    headers: header_multipart,
    url: `${Endpoint.API_SERVICE}${Endpoint.deleteProperty}`,
    data: body
  });
  console.log('log-is-delete', response);
};
export const deleteDataBisnis = async (param: any) => {
  const cid =
    typeof param !== 'undefined' && param !== null ? param.contentId : null;
  const body = {
    lini_id: cid
  };
  console.log('log-message', cid);

  const response = await axios({
    method: 'POST',
    headers: header_multipart,
    url: `${Endpoint.API_SERVICE}${Endpoint.deleteBusiness}`,
    data: body
  });
  console.log('log-is-delete', response);
};

export const sendContentBisnis = async (formData: any) => {
  console.log('log-message-data-post', formData);
  const response = await axios({
    method: 'POST',
    headers: header_multipart,
    url: `${Endpoint.API_SERVICE}${Endpoint.newBusiness}`,
    data: formData,
  });
  const status =
    typeof response !== 'undefined' && response != null ? response.status : 404;
  // console.log('data-response ', response);

  if (status == 200) {
    const result =
      typeof response !== 'undefined' && response != null
        ? response.data
        : null;
    const message =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.message
        : '';
    const statusCode =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.code
        : '';
    console.log('here-status: ', result);
    if (statusCode == 0) {
      const data =
        typeof result !== 'undefined' && result != null && result != ''
          ? result.data
          : '';
      toast.success(message);
      return data;
    } 
      return null;
    
  }
  return null;
};

export const editContentBisnis = async (formData: any) => {
  // console.log('log-message-data-post', param);
  const response = await axios({
    method: 'POST',
    headers: header_multipart,
    url: `${Endpoint.API_SERVICE}${Endpoint.updateBisnis}`,
    data: formData
  });
  const status =
    typeof response !== 'undefined' && response != null ? response.status : 404;
  console.log('data-response ', response);

  if (status == 200) {
    const result =
      typeof response !== 'undefined' && response != null
        ? response.data
        : null;
    const message =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.message
        : '';
    const statusCode =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.code
        : '';
    // console.log('here-status: ', statusCode);
    if (statusCode == 0) {
      const data =
        typeof result !== 'undefined' && result != null && result != ''
          ? result.data
          : '';
      toast.success(message);
      return data;
    } 
    toast.error(message);
    return null;
  }
  return null;
};