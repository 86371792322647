/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import {Endpoint, header_multipart} from '@app/services/endPoint';
import axios from 'axios';
import {toast} from 'react-toastify';

export const sendContent = async (param: any) => {
  // console.log('log-message-data-post', param);
  const response = await axios({
    method: 'POST',
    headers: header_multipart,
    url: `${Endpoint.API_SERVICE}${Endpoint.newContent}`,
    data: param
  });
  const status =
    typeof response !== 'undefined' && response != null ? response.status : 404;
  console.log('data-response ', response);

  if (status == 200) {
    const result =
      typeof response !== 'undefined' && response != null
        ? response.data
        : null;
    const message =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.message
        : '';
    const statusCode =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.code
        : '';
    // console.log('here-status: ', statusCode);
    if (statusCode == 0) {
      const data =
        typeof result !== 'undefined' && result != null && result != ''
          ? result.data
          : '';
      toast.success(message);
      return data;
    }
    return null;
  }
  return null;
};

export const editContent = async (param: any) => {
  // console.log('log-message-data-post', param);
  const response = await axios({
    method: 'POST',
    headers: header_multipart,
    url: `${Endpoint.API_SERVICE}${Endpoint.updateContent}`,
    data: param
  });
  const status =
    typeof response !== 'undefined' && response != null ? response.status : 404;
  console.log('data-response ', response);

  if (status == 200) {
    const result =
      typeof response !== 'undefined' && response != null
        ? response.data
        : null;
    const message =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.message
        : '';
    const statusCode =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.code
        : '';
    if (statusCode == 0) {
      const data =
        typeof result !== 'undefined' && result != null && result != ''
          ? result.data
          : '';
      toast.success(message);
      return data;
    }
    return null;
  }
  return null;
};

export const deleteData = async (param: any) => {
  const cid =
    typeof param !== 'undefined' && param !== null ? param.contentId : null;
  const body = {
    "content_id": cid
  };
  console.log('log-message', cid);

  const response = await axios({
    method: 'POST',
    headers: header_multipart,
    url: `${Endpoint.API_SERVICE}${Endpoint.deleteContent}`,
    data: body
  });
  console.log('log-is-delete', response);
};

export const deleteDataRagamAktifitas = async (param: any) => {
  const cid =
    typeof param !== 'undefined' && param !== null ? param.ragamId : null;
  const body = {
    "id_ragam": cid
  };

  console.log('log-message', body);

  const response = await axios({
    method: 'POST',
    headers: header_multipart,
    url: `${Endpoint.API_SERVICE}${Endpoint.deleteRagamAktifitas}`,
    data: body
  });
  const status =
    typeof response !== 'undefined' && response != null ? response.status : 404;
  console.log('log-is-delete', status);
  
  return response;
};
