/* eslint-disable react/no-unescaped-entities */
import {SmallBox} from '@app/components';
import React from 'react';
import {ContentHeader} from '@components';

const Dashboard = () => {
  return (
    <div>
      <ContentHeader title="Dashboard" />

      <section className="content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <h3 className="pl-2 pr-2 pt-2">Tentang Kami</h3>
                  <div className="p-2">
                    <p>
                      Pembangunan berkelanjutan, karya yang bermakna, dan
                      kemanusiaan. Sejak tahun 1960, Jakpro dibangun dengan misi
                      mengembangkan komunitas dan karya yang mengedepankan
                      prinsip prinsip keberlanjutan (<i>sustainability</i>).
                      Sebagai perusahaan professional milik Pemerintah Jakarta,
                      Jakpro Jakarta, Jakpro konsisten menumbuhkan usaha (
                      <i>venture</i>
                      ), sesuai perannya sebagai mitra terdepan dalam membangun
                      Jakarta sebagai <i>liveable city</i>.
                    </p>
                    <p>
                      Membangun Jakarta sebagai <i>liveable city</i> merupakan
                      salah satu visi Pemerintah Provinsi DKI Jakarta yang
                      mendorong dan memotivasi Jakpro untuk mengembangkan
                      usahanya dari properti, infrastruktur, utilitas,
                      informasi, komunikasi serta finansial. Melalui beragam
                      karya dalam bidang strategis tersebut, Jakpro menyentuh
                      kehidupan beragam anggota masyarakat di Jakarta, dimana
                      masyarakat saling terhubung dan saling memberi dukungan.
                    </p>
                    <p>
                      Dalam mengembangkan usahanya, Jakpro secara konsisten
                      berkomitmen untuk mengembangkan keberlanjutan, karya yang
                      bermakna dari segi fungsi dan estetika serta kemanusiaan
                      sebagai landasannya.
                    </p>
                  </div>
                </div>
                <div className="icon">
                  <i className="ion ion-information" />
                </div>
                <a href="/" className="small-box-footer">
                  {/* More info <i className="fas fa-arrow-circle-right" /> */}
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-success">
                <div className="inner">
                  <h3 className="pl-2 pr-2 pt-2">
                    Visi<sup style={{fontSize: '20px'}}> & </sup> Misi
                  </h3>
                  <div className="p-2">
                    <p>
                      <strong>Visi</strong>
                      <br />
                      Menjadi perusahaan yang terdepan dalam menjadikan Jakarta
                      yang lebih baik.
                    </p>
                    <p>
                      <strong>Misi</strong>
                    </p>
                    <p>
                      <b>Potensi.</b> Jakpro selalu mengkoordinasikan dan
                      menyatukan potensi korporasi agar sejalan dengan visi dan
                      misi Pemerintah Provinsi DKI Jakarta.
                    </p>
                    <p>
                      <b>Budaya.</b> Jakpro menumbuhkan budaya korporasi yang
                      mengedepankan kolaborasi sekaligus memiliki daya saing
                      sebagai individu.
                    </p>
                    <p>
                      <b>Manfaat dan kapitalisasi.</b> Jakpro meningkatkan
                      nilai, manfaat dan kapitalisasi perusahaan melalui karya
                      properti dan infrastruktur yang inovatif, berdaya saing
                      tinggi berwawasan lingkungan, untuk memenuhi kebutuhan
                      pemangku kepentingan.
                    </p>
                  </div>
                </div>
                <div className="icon">
                  <i className="ion ion-information" />
                </div>
                <a href="/" className="small-box-footer">
                  {/* More info <i className="fas fa-arrow-circle-right" /> */}
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3 className="pl-2 pr-2 pt-2">Sejarah</h3>
                  <div className="p-2">
                    <p>
                      JAKPRO merancang dan menciptakan ekosistem bukan hanya
                      untuk kehidupan masyarakat kini, tapi juga masa depan.
                      Selain melibatkan komunitas, proyek-proyek JAKPRO
                      melibatkan pihak yang mendukung terlaksananya prinsip
                      keberlanjutan. Prinsip keberlanjutan dimulai bukan hanya
                      ketika mendesain, tapi juga dalam memanfaatkan materi yang
                      sudah ada.
                    </p>
                    <p>
                      Proyek-proyek JAKPRO bertujuan membawa kebahagiaan warga
                      Jakarta, bukan hanya dari aspek pemenuhan kebutuhan fisik
                      dan estetika, tapi juga dampak sosial pada masyarakat.
                      Proyek-proyek JAKPRO mengutamakan meningkatkan nilai
                      ekonomi pada komunitas sekitar, tidak hanya untuk jangka
                      pendek, tetapi juga jangka panjang, sekaligus menggali
                      kemampuan komunitas baru.
                    </p>
                    <p>
                      JAKPRO tidak hanya menciptakan teknis dan kualitas
                      struktur serta sustainability pada proyek-proyeknya,
                      tetapi juga mengeksplor sisi kemanusiaan dan emosi dari
                      kita semua, yaitu keindahan kreasi seni manusia.
                      Proyek-projek diharapkan turut memberi kenyamanan dan
                      kebahagiaan bukan hanya melalui fungsi teknis, tetapi juga
                      tampilannya yang bermakna estetika.
                    </p>
                  </div>
                </div>
                <div className="icon">
                  <i className="ion ion-information" />
                </div>
                <a href="/" className="small-box-footer">
                  {/* More info <i className="fas fa-arrow-circle-right" /> */}
                </a>
              </div>
            </div>
            {/* <div className="col-lg-3 col-6">
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>Dewan Komisaris</h3>

                  <p>Dewan Komisaris</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph" />
                </div>
                <a href="/" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
