/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import i18n from '@app/utils/i18n';
import CustomActionButton from '@app/components/media/CustomActionButton';
import * as MediaService from '../../../services/controller/content/media/fetch_content_data';

const Penguman = () => {
  const [announce, setAnnounce] = useState([]);
  const fetchAnnouncement = async () => {
    try {
      const paramType = '4';
      const response = await MediaService.getDataMedia(paramType);
      // console.log('log-response-inner-type', response);
      if (response != null) {
        setAnnounce(response);
      }
    } catch (error) {
      setAnnounce([]);
    }
  };

  useEffect(() => {
    fetchAnnouncement();
  }, []);
  return (
    <div>
      <ContentHeader title={i18n.t('menusidebar.label.pengumuman')} />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body p-0">
              <table className="table table-striped projects">
                <thead>
                  <tr>
                    {/* <th className="text-center">Tanggal</th> */}
                    <th className="text-center">Tanggal Publikasi</th>
                    <th className="text-center">Judul</th>
                    <th className="text-center">Sub-Judul</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {announce.map((dat, i) => (
                    <tr key={i}>
                      {/* <td className="text-left">{dat['date_create']}</td> */}
                      <td className="text-left">{dat['date_publish']}</td>
                      <td className="text-left">{dat['title']}</td>
                      <td className="text-left">{dat['sub_title']}</td>
                      <td>
                        <CustomActionButton contentId={dat['content_id']} />
                        {/* <a
                          className="btn btn-info btn-sm"
                          href="#"
                          style={{
                            marginRight: 5,
                            marginBottom: 5,
                            marginTop: 5
                          }}
                        >
                          <i className="fas fa-eye"> </i>
                          View
                        </a>
                        <a
                          className="btn btn-info btn-sm"
                          href="#"
                          style={{
                            marginRight: 5,
                            marginBottom: 5,
                            marginTop: 5
                          }}
                        >
                          <i className="fas fa-pencil-alt"> </i>
                          Edit
                        </a>
                        <a
                          className="btn btn-danger btn-sm"
                          href="#"
                          style={{
                            marginRight: 5,
                            marginBottom: 5,
                            marginTop: 5
                          }}
                        >
                          <i className="fas fa-trash"> </i>
                          Delete
                        </a> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card-footer"> </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Penguman;
