/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/react-in-jsx-scope */

import {ContentHeader} from '@app/components';
import RichTextEditor from '@app/components/RichTextEditor';
import {useFormik} from 'formik';
import {SetStateAction, useEffect, useMemo, useRef, useState} from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {PfButton} from '@profabric/react-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {log} from 'console';
import i18n from '@app/utils/i18n';

import * as Yup from 'yup';
// import * as MediaService from '../../services/controller/content/media/fetch_content_data';
// import * as MediaServicePost from '../../services/controller/content/media/post_content_data';
// import * as AboutService from '../../services/controller/content/about_us/fetch_about_us_data';

const ManageGallery = () => {
  const [language, setLanguage] = useState([]);
  const [contentType, setContentType] = useState([]);
  const [content, setContent] = useState('');
  const [optionsLang, setOptionsLang] = useState('');
  const [optionsContent, setOptionsContent] = useState('');

  const [filesThumb, setFilesThumb] = useState<any | null>(null);
  const [imagePreviewThumb, setImagePreviewThumb] = useState<any | null>(null);

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [progressInfos, setProgressInfos] = useState({val: []});
  const [message, setMessage] = useState([]);
  const [imageInfos, setImageInfos] = useState([]);
  const progressInfosRef = useRef(null);

  const [isAuthLoading, setAuthLoading] = useState(false);
  const [currentType, setCurrentType] = useState('Pilih tipe media');
  const [currentLang, setCurrentLang] = useState('Pilih tipe media');
  const location = useLocation();
  const navigate = useNavigate();

  const [contentDetail, setContentDetail] = useState('');
  const [titles, setTitles] = useState('');
  const [subtitle, setSubTitle] = useState('');
  const [imageBody, setImageBody] = useState('');

  const cid =
    typeof location.state !== 'undefined' && location.state != null
      ? location.state.cid.contentId
      : null;
  const dataType =
    typeof location.state !== 'undefined' && location.state != null
      ? location.state.type
      : null;
  const page =
    typeof location.state !== 'undefined' && location.state != null
      ? location.state.pages
      : null;

  console.log('ContentManagement', cid);
  const slugify = (str: any) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

  const getContent = (value: any) => {
    setContent(value);
  };

  const handleFileThumbnail = (event: any) => {
    setImagePreviewThumb('');
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const url = URL.createObjectURL(file);
      setImagePreviewThumb(url);
      setFilesThumb(file);
      console.log('log-data-image', file);
    }
  };

  const handleFileHeader = (event: any) => {
    const images = [];

    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]));
    }

    setSelectedFiles(event.target.files);
    setImagePreviews(images);
    setProgressInfos({val: []});
    setMessage([]);
  };

  const handleSelectedContentType = (e: any) => {
    console.log('selected', e.target.value);
    const selectedValue =
      typeof e !== 'undefined' && e != '' && e != null ? e.target.value : '';
    setCurrentType(selectedValue);
    let index = 0;
    let contentTypes = '';
    for (index = 0; index < contentType.length; index++) {
      const element = contentType[index];
      const value = element;
      console.log(value);
      if (value['content_name'] == selectedValue) {
        contentTypes = value['content_type_id'];
      }
    }
    console.log('selected type', contentTypes);
    setOptionsContent(contentTypes);
  };

  const handleSelectedLang = (e: any) => {
    console.log('selected', e.target.value);
    const selectedValue =
      typeof e !== 'undefined' && e != '' && e != null ? e.target.value : '';
    setCurrentLang(selectedValue);
    let index = 0;
    let lang = '';
    for (index = 0; index < language.length; index++) {
      const element = language[index];
      const value = element;
      console.log(value['lang_name']);
      if (value['lang_name'] == selectedValue) {
        lang = value['lang_id'];
      }
    }
    console.log('selected language', lang);
    setOptionsLang(lang);
  };

  // const {handleChange, values, handleSubmit, touched, errors} = useFormik({
  //   initialValues: {
  //     title: '',
  //     subtitle: ''
  //   },
  //   validationSchema: Yup.object({
  //     title: Yup.string()
  //       .min(10, 'Must be 20 characters or more')
  //       .required('Required'),
  //     subtitle: Yup.string().min(10, 'Must be 20 characters or more')
  //   }),
  //   onSubmit: (val) => {
  //     console.log('log-message', val, content, optionsLang, optionsContent);
  //     const param = {
  //       title: val.title,
  //       sub_title: val.subtitle,
  //       type_id: optionsContent,
  //       lang_id: optionsLang,
  //       content,
  //       slug: slugify(val.title),
  //       image_header: filesImageHeader,
  //       image_thumbnail: filesThumb
  //     };
  //     console.log('param-post', param);

  //     postContent(param);
  //   }
  // });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (titles.length == 0) {
      alert(`Must be 10 characters or more`);
    }

    const param = {
      title: titles,
      type_id: optionsContent,
      lang_id: optionsLang,
      content,
      slug: slugify(titles),
      image_header: selectedFiles,
      image_thumbnail: filesThumb
    };
    console.log('param', param);
    // postContent(param);
  };

  const postContent = async (param: any) => {
    // try {
    //   let result = null;
    //   setAuthLoading(true);
    //   if (dataType == 'edit') {
    //     console.log(`log-message-here ${dataType}`, param);
    //     result = await MediaServicePost.editContent(param);
    //   } else {
    //     console.log(`log-message-here ${dataType}`, param);
    //     result = await MediaServicePost.sendContent(param);
    //   }
    //   console.log('log-result-post', result['content_type_id']);
    //   setAuthLoading(false);
    //   if (result['content_type_id'] == 1) {
    //     navigate('/news');
    //   } else if (result['content_type_id'] == 2) {
    //     navigate('/broadcast');
    //   } else if (result['content_type_id'] == 3) {
    //     navigate('/story');
    //   } else if (result['content_type_id'] == 4) {
    //     navigate('/announcement');
    //   }
    //   setAuthLoading(false);
    // } catch (error: any) {
    //   toast.error(error.message);
    //   setAuthLoading(false);
    // }
  };

  const fetchContentType = async () => {
    // try {
    //   const response = await MediaService.getContentType();
    //   console.log('log-response-inner-type', response);
    //   if (response != null) {
    //     setContentType(response);
    //   }
    // } catch (error) {
    //   setContentType([]);
    // }
  };

  const fetchContentLang = async () => {
    // try {
    //   const response = await MediaService.getLanguage();
    //   console.log('log-response-inner-language', response);
    //   if (response != null) {
    //     setLanguage(response);
    //   }
    // } catch (error) {
    //   setLanguage([]);
    // }
  };

  const fetchDetail = async () => {
    // try {
    //   const paramCid = cid;
    //   const pages = page;
    //   let response = null;
    //   console.log('paramCid', paramCid);
    //   if (pages !== 'about') {
    //     log('here');
    //     response = await MediaService.getDetail(paramCid);
    //   } else {
    //     response = await AboutService.getDetail(paramCid);
    //   }
    //   // console.log('log-response-inner-type', response['content']);
    //   if (response != null) {
    //     const title =
    //       typeof response !== 'undefined' &&
    //       response !== null &&
    //       response !== 'null'
    //         ? response.title
    //         : '';
    //     const subtitle =
    //       typeof response !== 'undefined' &&
    //       response !== null &&
    //       response !== 'null'
    //         ? response.sub_title
    //         : '';
    //     const image =
    //       typeof response !== 'undefined' &&
    //       response !== null &&
    //       response !== 'null'
    //         ? response.image
    //         : '';
    //     const imageBody =
    //       typeof response !== 'undefined' &&
    //       response !== null &&
    //       response !== 'null'
    //         ? image.thumbnail_path
    //         : '';
    //     const imageHeader =
    //       typeof image !== 'undefined' && image !== null && image !== 'null'
    //         ? image.header_path
    //         : '';
    //     const content =
    //       typeof response !== 'undefined' &&
    //       response !== null &&
    //       response !== 'null'
    //         ? response.content
    //         : '';
    //     setContent(content);
    //     setTitles(title);
    //     setSubTitle(subtitle);
    //     setImageHeader(imageHeader);
    //     setImageBody(imageBody);
    //     setImagePreviewHead(imageHeader);
    //     setImagePreviewThumb(imageBody);
    //   }
    // } catch (error) {
    //   setContent('');
    // }
  };

  useEffect(() => {
    fetchContentType();
    fetchContentLang();
    if (dataType == 'edit') {
      fetchDetail();
    }
  }, []);

  return (
    <div>
      <ContentHeader
        title={
          dataType == 'edit' && dataType != null
            ? i18n.t('menusidebar.label.editContent')
            : i18n.t('menusidebar.label.newContent')
        }
      />
      <div className="card card-warning">
        <div className="card-header">
          <h3 className="card-title">Content Management</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Title</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="title"
                      name="title"
                      type="title"
                      // placeholder={title}
                      onChange={(eText) => setTitles(eText.target.value)}
                      value={titles}
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Content Type</label>
                  <select
                    className="form-control"
                    value={currentType}
                    onChange={(e) => handleSelectedContentType(e)}
                  >
                    {contentType.map((opt, i) => (
                      <option key={i}>{opt['content_name']}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Language</label>
                  <select
                    className="form-control"
                    value={currentLang}
                    onChange={(e) => handleSelectedLang(e)}
                  >
                    {language.map((opt, i) => (
                      <option key={i}>{opt['lang_name']}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="exampleInputFile">Image / Thumbnail</label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="fileHeader"
                        onChange={(e) => handleFileThumbnail(e)}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        Choose file
                      </label>
                    </div>
                    <div className="input-group-append">
                      <span className="input-group-text">Upload</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="exampleInputFile">Gallery</label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        name="file"
                        className="custom-file-input"
                        id="file"
                        multiple
                        // accept="image/*"
                        onChange={(e) => handleFileHeader(e)}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        Choose file
                      </label>
                    </div>
                    <div className="input-group-append">
                      <span className="input-group-text">Upload</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  {imagePreviews && (
                    <div className="row mt-2 ml-2">
                      {imagePreviews.map((img, i) => {
                        return (
                          <img
                            className="preview mt-2 mr-2"
                            src={img}
                            alt={`image-${i}`}
                            key={i}
                            style={{
                              width: '40%'
                            }}
                          />
                        );
                      })}
                    </div>
                  )}

                  {message.length > 0 && (
                    <div className="alert alert-secondary mt-2" role="alert">
                      <ul>
                        {message.map((item, i) => {
                          return <li key={i}>{item}</li>;
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  {imagePreviewThumb && (
                    <div className="row mt-2 ml-2">
                      <img
                        className="preview mt-2 mr-2"
                        src={imagePreviewThumb}
                        style={{
                          width: '40%'
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-1">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
              {/* <PfButton
                className="btn btn-primary sm-6"
                block
                type="submit"
                loading={isAuthLoading}
                // disabled={isFacebookAuthLoading || isGoogleAuthLoading}
              >
                Submit
              </PfButton> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ManageGallery;
