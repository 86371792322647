/* eslint-disable eqeqeq */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import i18n from '@app/utils/i18n';
import * as AuthService from '../../services/controller/auth/auth';

const UserManagement = () => {
  const [userList, setUserList] = useState([]);
  const fetchUser = async () => {
    try {
      const paramType = '1';
      const response = await AuthService.getUser(paramType);
      // console.log('log-response-inner-type', response);
      if (response != null) {
        setUserList(response);
      }
    } catch (error) {
      setUserList([]);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);
  return (
    <div>
      <ContentHeader title={i18n.t('menusidebar.label.user')} />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <div className="card-tools">
                <a href="/add-user" className="btn btn-tool">
                  {i18n.t('main.label.addUser')}
                  <i
                    className="fa fa-plus"
                    style={{paddingLeft: 15, paddingRight: 15}}
                  />
                </a>
              </div>
            </div>
            <div className="card-body p-0">
              <table className="table table-striped projects">
                <thead>
                  <tr>
                    <th className="text-center">ID</th>
                    <th className="text-center">User Name</th>
                    <th className="text-center">Email</th>
                    <th className="text-center">Join Date</th>
                    <th className="text-center">Role</th>
                    <th className="text-center" style={{width: '10%'}}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userList.map((user, i) => (
                    <tr key={i}>
                      <td className="text-center">{user['user_id']}</td>
                      <td className="text-center">{user['user_name']}</td>
                      <td className="text-center">{user['user_email']}</td>
                      <td className="text-center">{user['date_created']}</td>
                      <td className="text-center">
                        {user['role_id'] != 1 ? 'Admin' : 'Super Admin'}
                      </td>
                      {/* <td className="text-left">
                        <div
                          dangerouslySetInnerHTML={{__html: news['content']}}
                        />
                      </td> */}
                      <td className="text-left">
                        <a
                          className="btn btn-info btn-sm"
                          href="#"
                          style={{
                            marginRight: 5,
                            marginBottom: 5,
                            marginTop: 5
                          }}
                        >
                          <i className="fas fa-eye"> </i>
                          View
                        </a>
                        <a
                          className="btn btn-info btn-sm"
                          href="#"
                          style={{
                            marginRight: 5,
                            marginBottom: 5,
                            marginTop: 5
                          }}
                        >
                          <i className="fas fa-pencil-alt"> </i>
                          Edit
                        </a>
                        <a
                          className="btn btn-danger btn-sm"
                          href="#"
                          style={{
                            marginRight: 5,
                            marginBottom: 5,
                            marginTop: 5
                          }}
                        >
                          <i className="fas fa-trash"> </i>
                          Delete
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="card-footer">Footer</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserManagement;
