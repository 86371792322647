// V2
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import {Endpoint, header_general, header_multipart} from '@app/services/endPoint';
import {removeWindowClass} from '@app/utils/helpers';
import axios from 'axios';
import {toast} from 'react-toastify';

export const loginByAuth = async (email: string, password: string) => {
  let token = '';

  const item = {
    user_email: email,
    user_password: password
  };

  const response = await axios({
    method: 'POST',
    url: `${Endpoint.API_SERVICE}${Endpoint.login}`,
    data: item,
    headers: header_multipart
  });

  if (response.status == 200) {
    const result =
      typeof response !== 'undefined' && response != null
        ? response.data
        : null;
    const message =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.message
        : '';
    const statusCode =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.code
        : '';
    // console.log("here-status: ", statusCode);

    if (statusCode == 0) {
      // console.log("here-code-success");
      const data =
        typeof result !== 'undefined' && result != null && result != ''
          ? result.data
          : '';
      token =
        typeof data !== 'undefined' && data != null && result != ''
          ? data.user_token
          : '';
      // console.log("here-code-success", data);
      localStorage.setItem('token', token);
      removeWindowClass('login-page');
      removeWindowClass('hold-transition');
      return token;
    }
    // console.log("here-code-success", result);
    // console.log("here-code");
    toast.error(message || 'Failed');
    return null;
  }
  // console.log("log-status-success");
  return null;
};

export const getUser = async (param: any) => {
  const parameter =
    typeof param != 'undefined' && param != null && param != '' ? param : '';
  const response = await axios({
    method: 'GET',
    headers: header_general,
    url: `${Endpoint.API_SERVICE}${Endpoint.userList}?t=${parameter}`
  });
  console.log('log-response', response);

  const status =
    typeof response !== 'undefined' && response != null ? response.status : 404;
  if (status == 200) {
    const result =
      typeof response !== 'undefined' && response != null
        ? response.data
        : null;
    const message =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.message
        : '';
    const statusCode =
      typeof result !== 'undefined' && result != null && result != ''
        ? result.code
        : '';
    console.log('here-status: ', statusCode);

    if (statusCode == 0) {
      const data =
        typeof result !== 'undefined' && result != null && result != ''
          ? result.data
          : '';
      return data;
    }
    return null;
  }
  return null;
};

export const registerByAuth = async (email: string, password: string) => {
  const token = await '';
  localStorage.setItem('token', token);
  removeWindowClass('register-page');
  removeWindowClass('hold-transition');
  return token;
};
