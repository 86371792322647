/* eslint-disable prettier/prettier */
/* eslint-disable no-empty */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/react-in-jsx-scope */
import {ContentHeader} from '@app/components';
import RichTextEditor from '@app/components/RichTextEditor';
import {useFormik} from 'formik';
import {SetStateAction, useEffect, useMemo, useRef, useState} from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {PfButton} from '@profabric/react-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {log} from 'console';
import i18n from '@app/utils/i18n';
import * as MediaService from '../../../services/controller/content/media/fetch_content_data';
import * as RagamService from '../../../services/controller/content/ragam_aktifitas/create_ragam_aktifitas';

const CreateAcivity = () => {
  const [language, setLanguage] = useState([]);
  const [titles, setTitles] = useState('');
  const [location, setLocation] = useState('');
  const [optionsLang, setOptionsLang] = useState('');
  const [filesThumb, setFilesThumb] = useState<any | null>(null);
  const [imagePreviewThumb, setImagePreviewThumb] = useState<any | null>(null);
  const [currentLang, setCurrentLang] = useState('Pilih tipe media');
  const [datePublish, setDatePublish] = useState('');
  const [contact, setContact] = useState('');
  const [content, setContent] = useState('');
  const [contentData, setContentData] = useState('');

  const locations = useLocation();
  const navigate = useNavigate();

  const cid =
    typeof locations.state !== 'undefined' && locations.state != null
      ? locations.state.cid.contentId
      : null;

  const dataType =
    typeof locations.state !== 'undefined' && locations.state != null
      ? locations.state.type
      : null;

  const getContent = (value: any) => {
    setContentData(value);
  };

  const slugify = (str: any) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (titles.length == 0) {
      alert(`Must be 10 characters or more`);
    }
    let param = {};
    if (dataType == 'edit') {
      param = {
        id_ragam: cid,
        title: titles,
        content: contentData,
        placeEvent: location,
        slug: slugify(titles),
        lang_id: optionsLang,
        image_thumbnail: filesThumb,
        date_publish: datePublish
      };
    } else {
      param = {
        title: titles,
        content: contentData,
        place: location,
        slug: slugify(titles),
        rergister_at: contact,
        lang_id: optionsLang,
        image_thumbnail: filesThumb,
        date_release: datePublish
      };
    }
    // console.log('singo: ', param);
    postContent(param);
  };

  const fetchContentLang = async () => {
    try {
      const response = await MediaService.getLanguage();
      console.log('log-response-inner-language', response);
      if (response != null) {
        setLanguage(response);
      }
    } catch (error) {
      setLanguage([]);
    }
  };

  const postContent = async (param: any) => {
    try {
      log('data', param);
      let result = null;
      if (dataType == 'edit') {

      } else {
        result = await RagamService.sendContent(param);
        navigate('/media/variety-of-activities');
      }
    } catch (error: any) {
      // toast.error(error.message);
      log('data', error);
    }
  };

  const handleFileThumbnail = (event: any) => {
    setImagePreviewThumb('');
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const url = URL.createObjectURL(file);
      setImagePreviewThumb(url);
      setFilesThumb(file);
      console.log('log-data-image', file);
    }
  };

  const handleSelectedLang = (e: any) => {
    console.log('selected', e.target.value);
    const selectedValue =
      typeof e !== 'undefined' && e != '' && e != null ? e.target.value : '';
    setCurrentLang(selectedValue);
    let index = 0;
    let lang = '';
    for (index = 0; index < language.length; index++) {
      const element = language[index];
      const value = element;
      console.log(value['lang_name']);
      if (value['lang_name'] == selectedValue) {
        lang = value['lang_id'];
      }
    }
    console.log('selected language', lang);
    setOptionsLang(lang);
  };
  useEffect(() => {
    fetchContentLang();
    if (dataType == 'edit') {
      //   fetchDetail();
    }
  }, []);
  return (
    <div>
      <ContentHeader
        title={i18n.t('menusidebar.label.variety-of-activities')}
      />
      <div className="card card-warning">
        <div className="card-header">
          <h3 className="card-title">
            Create {i18n.t('menusidebar.label.variety-of-activities')}
          </h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Title</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="title"
                      name="title"
                      type="title"
                      placeholder="Activity Name"
                      onChange={(eText) => setTitles(eText.target.value)}
                      value={titles}
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Place</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="place"
                      name="place"
                      type="place"
                      placeholder="Activity Location"
                      onChange={(text) => setLocation(text.target.value)}
                      value={location}
                      // isValid={touched.subtitle && !errors.subtitle}
                      // isInvalid={touched.subtitle && !!errors.subtitle}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Language</label>
                  <select
                    className="form-control"
                    value={currentLang}
                    onChange={(e) => handleSelectedLang(e)}
                  >
                    {language.map((opt, i) => (
                      <option key={i}>{opt['lang_name']}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Date Publish</label>
                  <div
                    className="input-group date"
                    id="reservationdate"
                    data-target-input="nearest"
                  >
                    <input
                      type="date"
                      className="form-control datetimepicker-input"
                      data-target="#reservationdate"
                      onChange={(text) => setDatePublish(text.target.value)}
                      value={datePublish}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="exampleInputFile">Image & Thumbnail</label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        name="file"
                        className="custom-file-input"
                        id="file"
                        onChange={(e) => handleFileThumbnail(e)}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        Choose file
                      </label>
                    </div>
                    <div className="input-group-append">
                      <span className="input-group-text">Upload</span>
                    </div>
                  </div>
                  <img
                    className="mt-5 col-sm-6"
                    alt=""
                    src={`${imagePreviewThumb}`}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Register at</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="contact"
                      name="contact"
                      type="contact"
                      placeholder="https://your-registration-site.domain"
                      onChange={(text) => setContact(text.target.value)}
                      value={contact}
                      // isValid={touched.subtitle && !errors.subtitle}
                      // isInvalid={touched.subtitle && !!errors.subtitle}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>Content</label>
                <RichTextEditor
                  initialValue={contentData}
                  getValue={getContent}
                />
              </div>
            </div>
            <div className="col-sm-1">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateAcivity;
