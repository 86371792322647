/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as MediaServicePost from '../../services/controller/content/media/post_content_data';
/* eslint-disable react/react-in-jsx-scope */
const CustomActionButton = (ragamId: any) => {
  const navigate = useNavigate();
  const [isAuthLoading, setAuthLoading] = useState(false);

  // const toDetail = () => {
  //   navigate('/detail', {state: {cid: content_id}});
  // };

  const toEdit = () => {
    navigate('/news/edit-content', {
      state: {cid: ragamId, type: 'edit'}
    });
  };

  const toDelete = async () => {
    try {
      setAuthLoading(true);
      const result = await MediaServicePost.deleteDataRagamAktifitas(ragamId);
      console.log('log-result-post', JSON.stringify(result));
      if (result != null) {
        setAuthLoading(false);
        toast.success('Data Berhasil Dihapus');
        window.location.reload();
      } else {
        toast.success('Data Gagal Dihapus');
      }
    } catch (error: any) {
      toast.error(error.message);
      setAuthLoading(false);
    }
  };

  return (
    <div className="d-flex justify-content-center">
      {/* <a
        className="btn btn-info btn-sm"
        onClick={() => {
          toDetail();
        }}
        style={{
          marginRight: 5,
          marginBottom: 5,
          marginTop: 5
        }}
      >
        <i className="fas fa-eye"> </i>
        View
      </a> */}
      {/* <a
        className="btn btn-info btn-sm"
        // href="#"
        onClick={() => {
          toEdit();
        }}
        style={{
          marginRight: 5,
          marginBottom: 5,
          marginTop: 5
        }}
      >
        <i className="fas fa-pencil-alt"> </i>
        Edit
      </a> */}
      <a
        className="btn btn-danger btn-sm"
        href="#"
        onClick={() => {
          toDelete();
        }}
        style={{
          marginRight: 5,
          marginBottom: 5,
          marginTop: 5
        }}
      >
        <i className="fas fa-trash"> </i>
        Delete
      </a>
      &nbsp;
    </div>
  );
};

export default CustomActionButton;
