/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/react-in-jsx-scope */

import {ContentHeader} from '@app/components';
import RichTextEditor from '@app/components/RichTextEditor';
import {useFormik} from 'formik';
import {SetStateAction, useEffect, useMemo, useRef, useState} from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {PfButton} from '@profabric/react-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {log} from 'console';
import i18n from '@app/utils/i18n';

import * as MediaService from '../../services/controller/content/media/fetch_content_data';
import * as MediaServicePost from '../../services/controller/content/media/post_content_data';

const ContentManagement = () => {
  const [language, setLanguage] = useState([]);
  const [contentType, setContentDataType] = useState([]);
  const [contentData, setContentData] = useState('');
  const [optionsLang, setOptionsLang] = useState('');
  const [optionsContent, setOptionsContent] = useState('');

  const [filesThumb, setFilesThumb] = useState<any | null>(null);

  const [imagePreviewThumb, setImagePreviewThumb] = useState<any | null>(null);

  const [isAuthLoading, setAuthLoading] = useState(false);
  const [currentType, setCurrentType] = useState('Pilih tipe media');
  const [currentLang, setCurrentLang] = useState('Pilih tipe media');
  const location = useLocation();
  const navigate = useNavigate();

  const [titles, setTitles] = useState('');
  const [subtitle, setSubTitle] = useState('');
  const [datePublish, setDatePublish] = useState('');
  // const [imageHeader, setImageHeader] = useState('');
  const [imageBody, setImageBody] = useState('');

  const cid =
    typeof location.state !== 'undefined' && location.state != null
      ? location.state.cid.contentId
      : null;
  const dataType =
    typeof location.state !== 'undefined' && location.state != null
      ? location.state.type
      : null;

  console.log('ContentManagement', cid);
  const slugify = (str: any) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

  const getContent = (value: any) => {
    setContentData(value);
  };

  const handleFileThumbnail = (event: any) => {
    setImagePreviewThumb('');
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const url = URL.createObjectURL(file);
      setImagePreviewThumb(url);
      setFilesThumb(file);
      console.log('log-data-image', file);
    }
  };

  const handleSelectedContentType = (e: any) => {
    console.log('selected', e.target.value);
    const selectedValue =
      typeof e !== 'undefined' && e != '' && e != null ? e.target.value : '';
    setCurrentType(selectedValue);
    let index = 0;
    let contentTypes = '';
    for (index = 0; index < contentType.length; index++) {
      const element = contentType[index];
      const value = element;
      console.log(value);
      if (value['content_name'] == selectedValue) {
        contentTypes = value['content_type_id'];
      }
    }
    console.log('selected type', contentTypes);
    setOptionsContent(contentTypes);
  };

  const handleSelectedLang = (e: any) => {
    console.log('selected', e.target.value);
    const selectedValue =
      typeof e !== 'undefined' && e != '' && e != null ? e.target.value : '';
    setCurrentLang(selectedValue);
    let index = 0;
    let lang = '';
    for (index = 0; index < language.length; index++) {
      const element = language[index];
      const value = element;
      console.log(value['lang_name']);
      if (value['lang_name'] == selectedValue) {
        lang = value['lang_id'];
      }
    }
    console.log('selected language', lang);
    setOptionsLang(lang);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (titles.length == 0) {
      alert(`Must be 10 characters or more`);
    }
    let param = {};
    if (dataType == 'edit') {
      param = {
        content_id: cid,
        title: titles,
        sub_title: subtitle,
        type_id: optionsContent,
        lang_id: optionsLang,
        content: contentData,
        slug: slugify(titles),
        image_thumbnail: filesThumb,
        date_publish: datePublish
      };
    } else {
      param = {
        title: titles,
        sub_title: subtitle,
        type_id: optionsContent,
        lang_id: optionsLang,
        content: contentData,
        slug: slugify(titles),
        image_thumbnail: filesThumb,
        date_publish: datePublish
      };
    }
    // console.log('singo: ', param);
    postContent(param);
  };

  const postContent = async (param: any) => {
    try {
      log('data', param);
      let result = null;
      if (dataType == 'edit') {
        result = await MediaServicePost.editContent(param);
        if (result.content_type_id == '1') {
          navigate('/media/news');
        } else if (result.content_type_id == '2') {
          navigate('/media/broadcast');
        } else if (result.content_type_id == '3') {
          navigate('/media/story');
        } else if (result.content_type_id == '4') {
          navigate('/media/announcement');
        }
      } else {
        result = await MediaServicePost.sendContent(param);
        log('data-123', result.content_type_id);
        if (result.content_type_id == '1') {
          navigate('/media/news');
        } else if (result.content_type_id == '2') {
          navigate('/media/broadcast');
        } else if (result.content_type_id == '3') {
          navigate('/media/story');
        } else if (result.content_type_id == '4') {
          navigate('/media/announcement');
        }
      }
    } catch (error: any) {
      // toast.error(error.message);
      log('data', error);
    }
  };

  const fetchContentType = async () => {
    try {
      const response = await MediaService.getContentType();
      console.log('log-response-inner-type', response);
      if (response != null) {
        setContentDataType(response);
      }
    } catch (error) {
      setContentDataType([]);
    }
  };

  const fetchContentLang = async () => {
    try {
      const response = await MediaService.getLanguage();
      console.log('log-response-inner-language', response);
      if (response != null) {
        setLanguage(response);
      }
    } catch (error) {
      setLanguage([]);
    }
  };

  const fetchDetail = async () => {
    try {
      const paramCid = cid;
      const response = await MediaService.getDetail(paramCid);
      // console.log('log-response-inner-type', response['content']);
      if (response != null) {
        const title =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.title
            : '';
        const subtitle =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.sub_title
            : '';
        const image =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.image
            : '';
        const imageBody =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? image.thumbnail_path
            : '';
        const content =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.content
            : '';

        setContentData(content);
        setTitles(title);
        setSubTitle(subtitle);
        setImageBody(imageBody);
        setImagePreviewThumb(imageBody);
        // setImageHeader(imageHeader);
        // setImagePreviewHead(imageHeader);
      }
    } catch (error) {
      setContentData('');
    }
  };

  useEffect(() => {
    fetchContentType();
    fetchContentLang();
    if (dataType == 'edit') {
      fetchDetail();
    }
  }, []);

  return (
    <div>
      <ContentHeader
        title={
          dataType == 'edit' && dataType != null
            ? i18n.t('menusidebar.label.editContent')
            : i18n.t('menusidebar.label.newContent')
        }
      />
      <div className="card card-warning">
        <div className="card-header">
          <h3 className="card-title">Content Management</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Title</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="title"
                      name="title"
                      type="title"
                      // placeholder={title}
                      onChange={(eText) => setTitles(eText.target.value)}
                      value={titles}
                    />
                  </InputGroup>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Description</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="subtitle"
                      name="subtitle"
                      type="subtitle"
                      // placeholder="subtitle"
                      onChange={(text) => setSubTitle(text.target.value)}
                      value={subtitle}
                      // isValid={touched.subtitle && !errors.subtitle}
                      // isInvalid={touched.subtitle && !!errors.subtitle}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Content Type</label>
                  <select
                    className="form-control"
                    value={currentType}
                    onChange={(e) => handleSelectedContentType(e)}
                  >
                    {contentType.map((opt, i) => (
                      <option key={i}>{opt['content_name']}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Language</label>
                  <select
                    className="form-control"
                    value={currentLang}
                    onChange={(e) => handleSelectedLang(e)}
                  >
                    {language.map((opt, i) => (
                      <option key={i}>{opt['lang_name']}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="exampleInputFile">Image & Thumbnail</label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        name="file"
                        className="custom-file-input"
                        id="file"
                        onChange={(e) => handleFileThumbnail(e)}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        Choose file
                      </label>
                    </div>
                    <div className="input-group-append">
                      <span className="input-group-text">Upload</span>
                    </div>
                  </div>
                  <img
                    className="mt-5 col-sm-6"
                    alt=""
                    src={`${imagePreviewThumb}`}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Date Publish</label>
                  <div
                    className="input-group date"
                    id="reservationdate"
                    data-target-input="nearest"
                  >
                    <input
                      type="date"
                      className="form-control datetimepicker-input"
                      data-target="#reservationdate"
                      onChange={(text) => setDatePublish(text.target.value)}
                      value={datePublish}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>Content</label>
                <RichTextEditor
                  initialValue={contentData}
                  getValue={getContent}
                />
              </div>
            </div>
            <div className="col-sm-1">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
              {/* <PfButton
                className="btn btn-primary sm-6"
                block
                type="submit"
                loading={isAuthLoading}
                // disabled={isFacebookAuthLoading || isGoogleAuthLoading}
              >
                Submit
              </PfButton> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContentManagement;
