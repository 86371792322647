/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/alt-text */

import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import i18n from '@app/utils/i18n';
import {useLocation} from 'react-router-dom';
import {log} from 'console';
import {Endpoint} from '@app/services/endPoint';

import * as AboutService from '../../services/controller/content/about_us/fetch_about_us_data';

const DetailTentangKami = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [department, setDepartment] = useState('');
  const [imageBody, setImageBody] = useState('');
  const [year, setYear] = useState('');
  const [date, setDate] = useState('');

  const location = useLocation();
  const dataParam =
    typeof location !== 'undefined' && location != null
      ? location.state.cid
      : null;

  const fetchNewsDetail = async () => {
    try {
      const paramCid = dataParam.contentId;
      const response = await AboutService.getDetail(paramCid);
      console.log('log-response', response);
      if (response != null) {
        const title =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.title
            : '';
        const image =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.image
            : '';
        const imageBody =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? image.thumbnail_path
            : '';
        const year =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.year
            : '';

        const deskripsi =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.description
            : '';
        const department =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.department
            : '';
        const date_create =
          typeof response !== 'undefined' &&
          response !== null &&
          response !== 'null'
            ? response.date_create
            : '';

        setTitle(title);
        setDepartment(department);
        setDescription(deskripsi);
        setYear(year);
        setDate(date_create);
        setImageBody(imageBody);
      }
    } catch (error) {
      setDescription('');
    }
  };

  useEffect(() => {
    fetchNewsDetail();
  }, []);

  return (
    <div>
      {/* <ContentHeader title={`${i18n.t('menusidebar.label.news')} Detail`} /> */}
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body p-15 ml-5 mr-5">
              <div className="row">
                <div className="col-sm-6">
                  {imageBody !== '' && imageBody !== null ? (
                    <img
                      // className="col p-5"
                      src={Endpoint.BASE_URL_IMAGE + imageBody}
                      style={{
                        width: '80%'
                      }}
                    />
                  ) : (
                    <div />
                  )}
                </div>
                <div className="col-sm-6">
                  <h1>{title}</h1>
                  <h5>{department}</h5>
                  <div dangerouslySetInnerHTML={{__html: description}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DetailTentangKami;
